import "../Css/EditMetadata.css";
import React, { useState } from "react";
import Loading from "../Component/Loading";
import Table from "../Component/Table";
import InputField from "../Component/InputField";
import VideoDetailsCard from "../Component/VideoDetailsCard";
import EditCard from "../Component/EditCard";

function EditMetadata({
  showAlert,
  setAlert,
  initialiseVideo,
  sessionLocked,
  setSessionLocked,
  scroll,
  showVideoDetails,
  setShowVideoDetails,
  showresult,
  setSearchResult,
  setShowresult,
  searchResult,
  video,
  setVideo,
  setScreen,
  setShowOverlay,
  setIsActive,
  editMode,
  setEditMode,
  unchangedVideoDetails,
  setShowModal,
  idleHandler,
  setMinutes,
  setScroll,
  user,
  createNewKeywords,
  setCreateNewKeywords,
  isLoading,
  setIsLoading
}) {
  const [isTimeOut, setIsTimeOut] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ isSorted , setIsSorted ] = useState(undefined);

  const updateVideo = (updatedVideo) => {
    setVideo(updatedVideo);
  };

  return (
    <div className="edit-metadata">
      {isTimeOut && (
        <div className="timeOut-container">
          <div className="timeOut-heading-flex">
            <div className="header-card">Timeout</div>
            <svg
              onClick={() => {
                setIsTimeOut(false);
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M6 18L18 6M6 6L18 18"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div className="card-text">
            Your edit session has timed out due to inactivity. Changes are not
            saved.
          </div>
        </div>
      )}
      <div className="description-card">
        <h1 className="title-text edit-metadata-text">Video Metadata Tool</h1>
        <div className="description-text">
          <span className="no-wrap">
            This tool helps you tag videos quickly and easily, ensuring your
            videos have a wider audience reach and are more discoverable.
          </span>
          <span className="no-wrap">
            Save time by copying values from similar pre-existing videos or by
            adding your own keywords with just a few clicks.
          </span>
        </div>
      </div>
      <div className="card">
        <h3 className="header-card">1. Locate video</h3>
        <div className="body-card">
          <div className="card-text">Video title, Brightcove ID or Author</div>
          <InputField
            showAlert={showAlert}
            setAlert={setAlert}
            editMode={editMode}
            video={video}
            setEditMode={setEditMode}
            setShowresult={setShowresult}
            placeholder={"Search by video title, Brightcove ID or author"}
            setShowVideoDetails={setShowVideoDetails}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setSearchResult={setSearchResult}
            setVideo={initialiseVideo}
          ></InputField>
        </div>
        {loading && <Loading></Loading>}
        {showresult && <div>{searchResult.count} videos found</div>}
        {showresult && (
          <Table
            isSorted={isSorted}
            setIsSorted={setIsSorted}
            showAlert={showAlert}
            editMode={editMode}
            setAlert={setAlert}
            setShowresult={setShowresult}
            setLoading={setLoading}
            setEditMode={setEditMode}
            setVideo={initialiseVideo}
            searchResult={searchResult}
            setSessionLocked={setSessionLocked}
            importData={false}
            sessionLocked={sessionLocked}
            setSearchResult={setSearchResult}
            setShowVideoDetails={setShowVideoDetails}
          ></Table>
        )}
        {showVideoDetails && (
          <VideoDetailsCard
            scroll={scroll}
            existingResult={searchResult}
            saveSearchResult={setSearchResult}
            editMode={editMode}
            setLoading={setIsLoading}
            video={unchangedVideoDetails}
            setVideo={setVideo}
            sessionLocked={sessionLocked}
            importData={false}
            setSessionLocked={setSessionLocked}
            setScreen={setScreen}
            setShowOverlay={setShowOverlay}
            setEditMode={setEditMode}
            user={user}
          ></VideoDetailsCard>
        )}
      </div>
      {editMode && (
        <EditCard
          props={{
            createNewKeywords: createNewKeywords,
            setCreateNewKeywords: setCreateNewKeywords,
            setScroll:setScroll,
            setMinutes: setMinutes,
            idleHandler: idleHandler,
            setShowModal: setShowModal,
            unchangedVideoDetails: unchangedVideoDetails,
            scroll: scroll,
            editMode: editMode,
            setIsTimeOut: setIsTimeOut,
            setEditMode: setEditMode,
            setIsActive: setIsActive,
            setScreen: setScreen,
            video: video,
            setVideo: updateVideo,
          }}
        ></EditCard>
      )}
      {isLoading && <Loading></Loading>}
    </div>
  );
}

export default EditMetadata;
