const host = process.env.REACT_APP_MIDDLEWARE_HOST;
const API_KEY = process.env.REACT_APP_API_KEY;

export async function postSession(videoId, user) {
    let body = {
        videoId: videoId,
        userName: user
    }
    let url = host + 'sessions' ;
    const headers = { 'x-api-key' : API_KEY, 'Content-Type': 'application/json' };
    const result = await fetch(url, {
        method: 'POST', 
        body: JSON.stringify(body),
        headers: headers,
    }).catch( error => {
        console.log(`Error in writing the session : ` + error.message);
        return undefined
    })
    if( result && result.ok){
        let jsonBody = await result.json()
        return jsonBody
    }else{
        console.log(`Error in writing the session`);
        return undefined
    }
}

export async function getSession(videoId) {
    let url = host + 'sessions?videoIdFilter=' + videoId ;
    const headers = { 'x-api-key' : API_KEY };
    const result = await fetch(url, {
        method: 'GET', 
        headers: headers,
    }).catch( error => {
        console.log(`Error in reading the session : ` + error.message);
        return undefined
    })
    if( result && result.ok ){
        let jsonBody = await result.json()
        return jsonBody
    } else {
        console.log(`Error in reading the session`);
        return undefined
    }
} 

export async function deleteSession(sessionId) {
    let url = host + 'sessions/' + sessionId ;
    const headers = { 'x-api-key' : API_KEY };
    const result = await fetch(url, {
        method: 'DELETE', 
        headers: headers,

    }).catch( error => {
        console.log(`Error in deleting the session : ` + error.message);
        return undefined
    })
    if( result && result.ok){
        return true
    }else{
        console.log(`Error in deleting the session`);
        return undefined
    }
} 

export async function preSignedUrl(filename) {
    let url = host + 'files/presigned?fileName=' + filename
    const headers = { 'x-api-key' : API_KEY, 'Content-Type': 'application/json' };
    const result = await fetch(url, {
        method: 'PUT',
        headers: headers,
    }).catch( error => {
        console.log(`Error in getting the presigned url : ` + error.message);
        return undefined
    })
    if( result && result.ok){
        let jsonBody = await result.json()
        console.log(jsonBody)
        return jsonBody ? jsonBody.presignedUrl : ''
    }else{
        console.log(`Error in getting the presigned url`);
        return undefined
    }
}

export async function authenticated() {
    let url = host + 'authenticated'
    const result = await fetch(url, {
        method: 'GET',
        credentials: 'include'
    }).catch( error => {
        console.log(error);
        return undefined
    })
    if (result && result.ok) {
        return result.json();
    }
}

export async function logout() {
    let url = host + 'logout'
    const result = await fetch(url, {
        method: 'GET',
        credentials: 'include'
    }).catch( error => {
        console.log(error);
    })
    if (result && result.ok) {
        return result;
    }
}


