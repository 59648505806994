
import '../Css/ViewMetadata.css';
import React, { useState } from 'react';
import constants  from '../Utility/constant';
import { dateCreated, duration, availability, section } from '../Utility/functions';
import { saveMetadata, updateItems, updateImage } from '../API/CMS';
import { deleteSession } from '../API/Auth';
import ActiveIcon from '../Assets/svg/active_icon'

function ViewMetadata({ unchangedVideoDetails, setScroll, video, setScreen, isReviewPage, setShowresult, createNewKeywords }) {
  let sectiondata = section(video.custom_fields)
  const [errorToast, setErrorToast] = useState(undefined);

  const back = ( ) => {
    setScroll(undefined);
    setScreen(constants.EDIT_METADATA); 
    setShowresult(true); 
    window.scrollTo(0,0);
  }

  function geo( ) {
    if (video.geo) {
      if (video.geo.exclude_countries) {
        let countries = video.geo.countries ? video.geo.countries.toString() : ''
        return { option: 'Prevent viewing from the following countries' , countries:countries }
      } 
      else {
        let countries = video.geo.countries ? video.geo.countries.toString() : ''
        return { option: 'Allow viewing from the following countries' , countries:countries } 
      }
    } else {
      return { option: 'Unrestriced', countries:'' }
    }
  }

  const save = async ( active ) => {
    let error = false;
    let videoDetails = video;
    if( active ) {
      videoDetails.state = 'ACTIVE';
    } 
    if (unchangedVideoDetails?.images?.poster?.src !== videoDetails?.images?.poster?.src || 
      unchangedVideoDetails?.images?.thumbnail?.src !== videoDetails?.images?.thumbnail?.src ) {
        let payLoad = {
          poster: { url: videoDetails?.images?.poster?.src },
          thumbnail: { url: videoDetails?.images?.thumbnail?.src }
        }
        let result = await updateImage(videoDetails.id, payLoad);
        if( result ) {
          setErrorToast( result )
          window.scrollTo(0,0);
          error = true;
        }
    }
    if ( !error ) {
      let result = await saveMetadata(videoDetails);
      if( !result ) {
        setErrorToast( undefined )
        await deleteSession(video.id);
        if ( createNewKeywords.length > 0 ) {
          for (let index = 0; index < createNewKeywords.length; index++) {
            await updateItems(createNewKeywords[index]);
          }
        }
        if( active ) {
          setScreen(constants.VIDEO_SAVED_ACTIVATED)
        }
        else {
          setScreen(constants.VIDEO_SAVED)
        }
      } else {
        setErrorToast( result )
        window.scrollTo(0,0);
      }
    }
  }

  return (
    <div className='view-metadata'>
        <div className='view-metadata-desc-card'>
        { errorToast && <div className="approved-success-div bulk-reject-container">
            <div className="approved-success">
              <span className="body-base-black">{errorToast}</span>
              <svg onClick={() => {setErrorToast(undefined)}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M6 18L18 6M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </div>}
          <h1 className='view-metadata-heading view-metadata-text'>{ isReviewPage ? 'Review and confirm' : 'View metadata' }</h1>
          <span className='view-metadata-desc view-metadata-text'>{ isReviewPage ? constants.REVIEW_METADATA_DECS : constants.VIEW_METADATA_DECS }</span>
        </div>  
        <div className='video-title-info card-common'>
          
          <h3 className='h3-title view-metadata-text'>1. Video Title Information</h3>
          <div className='video-title-info-body card-body-common'>
            <img className='video-info-thumbnail' alt="poster" src={video?.images?.poster?.src}></img>
            <div className='video-info-row'>< div className='video-info-key'>Brightcove ID</div><div className='video-info-value'>{video.id}</div></div>
            <div className='video-info-row'>< div className='video-info-key'>Video title</div><div className='video-info-value view-metadata-desc-box'>{video.name && video.name.length > 150 ?
                            `${video.name.substring(0, 150)}...` : video.name }</div></div>
            <div className='video-info-row'>< div className='video-info-key'>Long Description</div><div className='video-info-value view-metadata-desc-box'>{video.long_description && video.long_description.length > 150 ?
                            `${video.long_description.substring(0, 150)}...` : video.long_description }</div></div>
            <div className='video-info-row'>< div className='video-info-key'>Date created</div><div className='video-info-value'>{dateCreated(video.created_at)}</div></div>
            <div className='video-info-row'>< div className='video-info-key'>Author</div><div className='video-info-value'>{video.custom_fields.author}</div></div>
            <div className='video-info-row'>< div className='video-info-key'>SEO owner</div><div className='video-info-value'>{video.custom_fields.seo_owner}</div></div>
            <div className='video-info-row'>< div className='video-info-key'>Source</div><div className='video-info-value'>{video.custom_fields.source}</div></div>
            <div className='video-info-row'>< div className='video-info-key'>Duration</div><div className='video-info-value'>{duration(video.duration)}</div></div>
            <div className='video-info-row'>< div className='video-info-key'>Video Orientation</div><div className='video-info-value'>{video.custom_fields.orientation}</div></div>
            <div className='video-info-row'>< div className='video-info-key'>Status</div><div className='video-info-value'><div className='video-details-value'> 
                                {  video.state === 'ACTIVE' && <ActiveIcon></ActiveIcon> }
                                { video.state === 'ACTIVE' && 'Active'}
                            </div></div></div>
            { isReviewPage && <button className='review-metadata-edit-button' onClick={( ) => {setScroll('video-details-card');setScreen(constants.EDIT_METADATA);}}>Edit</button>}
          </div>
        </div>
        <div className='section-metadata card-common'>
          <h3 className='h3-title view-metadata-text'>2. Section Metadata</h3>
          <div className='card-body-common'>
          <div className='video-info-row'>< div className='video-info-key'>Primary Section</div><div className='video-info-value'>{sectiondata.primary}</div></div>
          { ( isReviewPage || (sectiondata.secondary && sectiondata.secondary.filter( ele => ele !== undefined ).length > 0 )) && 
            <div className='video-info-row'>< div className='video-info-key'>Secondary Section</div>
            <div className='video-info-value'>
              {sectiondata.secondary.map( ( value ) =>  <div className='video-info-value'>{value}</div>)}
            </div>
            </div>  }
          { ( isReviewPage || (sectiondata.tertiary && sectiondata.tertiary.filter( ele => ele !== undefined  ).length > 0 )) && 
            <div className='video-info-row'>< div className='video-info-key'>Tertiary Section</div>
            <div className='video-info-value'>{sectiondata.tertiary.map( ( value ) => <div className='video-info-value'>{value}</div>)}</div></div>
          }
            { isReviewPage && <button className='review-metadata-edit-button' onClick={() => {setScroll('assign-section-card');setScreen(constants.EDIT_METADATA);}}>Edit</button>}
          </div>
        </div>
        <div className='general-video-details card-common'>
          <h3 className='h3-title view-metadata-text'>3. General Video Details</h3>
          <div className='card-body-common'>
            <div className='video-info-row'>< div className='video-info-key'>Enable ad-control</div><div className='video-info-value'>{video.custom_fields.ad_control}</div></div>
            <div className='video-info-row'>< div className='video-info-key'>Credit</div><div className='video-info-value'>{video.custom_fields.credits}</div></div>
            <div className='video-info-row'>< div className='video-info-key'>Live Worm</div><div className='video-info-value'>{video.custom_fields.live_worm}</div></div>
            <div className='video-info-row'>< div className='video-info-key'>Related article title</div><div className='video-info-value'>{video.custom_fields.call_to_action_related_link_text}</div></div>
            <div className='video-info-row'>< div className='video-info-key'>Related article URL</div><div className='video-info-value'>{video.custom_fields.call_to_action_related_link_url}</div></div>
            <div className='video-info-row'>< div className='video-info-key'>Fair dealing </div><div className='video-info-value'>{video.custom_fields.fairdealing}</div></div>
            <div className='video-info-row'>< div className='video-info-key'>Premium video</div><div className='video-info-value'>{video.custom_fields.premium_video}</div></div>
            <div className='video-info-row'>< div className='video-info-key'>Production type</div><div className='video-info-value'>{video.custom_fields.production_type}</div></div>
            <div className='video-info-row'>< div className='video-info-key'>Restriction</div><div className='video-info-value'>{video.custom_fields.restrictions}</div></div>
            <div className='video-info-row'>< div className='video-info-key'>Share to MRSS</div><div className='video-info-value'>{video.custom_fields.share_to_mrss}</div></div>
            <div className='video-info-row'>< div className='video-info-key'>Stream type</div><div className='video-info-value'>{video.custom_fields.stream_type}</div></div>
            <div className='video-info-row'>< div className='video-info-key'>Evergreen video</div><div className='video-info-value'>{video.custom_fields.evergreen_content}</div></div>
            <div className='video-info-row'>< div className='video-info-key'>Video type</div><div className='video-info-value'>{video.custom_fields.video_type}</div></div>
            { isReviewPage && <button className='review-metadata-edit-button' onClick={() => {setScroll('general-video-details');setScreen(constants.EDIT_METADATA);}}>Edit</button>}
          </div>
        </div>
        <div className='poster-thumbnail card-common'>
          <h3 className='h3-title view-metadata-text'>4. Poster & Thumbnail</h3>
          <div className='poster-thumbnail-column'>
            <div >
              <div className='poster-thumbnail-text'>Poster (minimum width = 640px)</div><img className='poster' alt="poster" src={video?.images?.poster?.src}></img>
            </div>
            <div ><div className='poster-thumbnail-text'>Thumbnail (minimum width = 160px)</div><img className='thumbnail' alt="thumbnail" src={video?.images?.thumbnail?.src}></img></div>  
          </div>
          { isReviewPage && <button className='review-metadata-edit-button' onClick={() => { setScroll('Edit-poster-thumbnail-card'); setScreen(constants.EDIT_METADATA);}}>Edit</button>}
        </div>
        <div className='metadata card-common'>
          <h3 className='h3-title view-metadata-text'>5. Metadata</h3>
          <div className='card-body-common'>
            <div className='video-info-row'>< div className='video-info-key'>Keywords </div><div className='video-info-value'>{video.custom_fields.keywords}</div></div>
            <div className='video-info-row'>< div className='video-info-key'>Place</div><div className='video-info-value'>{video.custom_fields.metadata_content_location}</div></div>
            <div className='video-info-row'>< div className='video-info-key'>People</div><div className='video-info-value'>{video.custom_fields.metadata_content_people}</div></div>
            <div className='video-info-row'>< div className='video-info-key'>Organisation</div><div className='video-info-value'>{video.custom_fields.metadata_content_organisation}</div></div>
            <div className='video-info-row'>< div className='video-info-key'>Events</div><div className='video-info-value'>{video.custom_fields.metadata_content_event}</div></div>
            <div className='video-info-row'>< div className='video-info-key'>Genre</div><div className='video-info-value'>{video.custom_fields.metadata_content_genre}</div></div>
            <div className='video-info-row'>< div className='video-info-key'>Subjects</div><div className='video-info-value'>{video.custom_fields.metadata_content_subject}</div></div>
            <div className='video-info-row'>< div className='video-info-key'>Recipe type</div><div className='video-info-value'>{video.custom_fields.metadata_content_recipe_type}</div></div>
            <div className='video-info-row'>< div className='video-info-key'>Technique</div><div className='video-info-value'>{video.custom_fields.metadata_content_technique}</div></div>
            <div className='video-info-row'>< div className='video-info-key'>Main ingredient</div><div className='video-info-value'>{video.custom_fields.metadata_content_main_ingredient}</div></div>
            <div className='video-info-row'>< div className='video-info-key'>Cuisine</div><div className='video-info-value'>{video.custom_fields.metadata_content_cuisine}</div></div>
            <div className='video-info-row'>< div className='video-info-key'>Dietary Needs/Diet</div><div className='video-info-value'>{video.custom_fields.metadata_content_dietary_needs}</div></div>
            { isReviewPage && <button className='review-metadata-edit-button' onClick={() => {setScroll('Add-metadata-card');setScreen(constants.EDIT_METADATA); }}>Edit</button>}
          </div>
        </div>
        <div className='publishing-settings card-common'>
          <h3 className='h3-title view-metadata-text'>6. Publishing Settings</h3>
          <div className='card-body-common'>
            <div className='video-info-row'>< div className='video-info-key'>Geo filtering</div><div className='video-info-value'><div>{geo().option}</div><div>{geo().countries}</div></div></div>
            <div className='video-info-row'>< div className='video-info-key'>Availability</div><div className='video-info-value'><div>{availability(video.schedule) ? 'Scheduled' : 'Available Immediately'}</div><div>{availability(video.schedule)}</div></div></div>
            { isReviewPage && <button className='review-metadata-edit-button' onClick={() => {setScroll('publishing-settings');setScreen(constants.EDIT_METADATA);}}>Edit</button>}
          </div>
        </div>
        <div className='button-div'>
          <button className='back-button' onClick={() => {back()} }>Back</button>
          { isReviewPage && <div className='save-button-div'>
            <button className='save-button' onClick={() => save(false)}>Save</button>
            <button className='save-activate-button' onClick={ () =>save(true)}>Save & Activate</button>
          </div>}
          
        </div>
        

      </div>
    
    
    
  );
}

export default ViewMetadata;
