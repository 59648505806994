
import { preSignedUrl } from './Auth'; 
const domain = process.env.REACT_APP_S3_BUCKET_DOMAIN;
let uploadDirectory = 'uploads/'

export const uploadFile = async (file) => {
  
  let clientUrl = await preSignedUrl(uploadDirectory+encodeURI(file.name))
  const result = await fetch(clientUrl, {
      method: 'PUT',
      headers: { 
        'Content-Type': 'multipart/form-data'
      },
      body: file
  }).catch( error => {
      console.log(`Error in putting the file : ` + error.message);
      return undefined
  })
  if( result && result.ok){
    return encodeURI(domain+file.name);
  }else{
      console.log(`Error in putting the file`);
      return undefined
  }
     
};