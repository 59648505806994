
import '../Css/Radio.css';
import React, { useState } from 'react';

function Radio({ defaultValue, heading, description, options, note, value,videoDetails,setVideoDetails}) {
    let key = Object.keys(value)[0];  
    function getValue() {
        if (key === 'schedule') {
            let Scheduled = value[key] && (value[key].starts_at || value[key].ends_at) 
            return Scheduled ? 'Scheduled' : 'Available immediately'
        } else if (key === 'evergreen_content') {
            return value[key] ? value[key] : 'No'
        } 
        else {
            return value[key] ? value[key] : defaultValue
        }
    }
    const [selectedOption, setSelectedOption] = useState(getValue());

    function handleOnChange(event) {
        event.preventDefault();
        if(key === 'schedule') {
            setSelectedOption(event.target.value);
            if (event.target.value === 'Available immediately') {
                setVideoDetails(key, null);
            } else {
                setVideoDetails(key, {});
            }
        }
        else if (key === 'evergreen_content') {
            setSelectedOption(event.target.value);
            if (event.target.value !== 'No') {
                setVideoDetails(key, event.target.value);
            }
        } 
        else {
            setSelectedOption(event.target.value);
            setVideoDetails(key, event.target.value);
        }
    }

    return (
        <div className="radio-container"> 
            <div className="radio-flex">
                <span className="primary-section-input-tx">{heading}</span>
                {note && <span className='radio-note-txt'>{note}</span>}
                {    options.map((option) => {
                    return <div className="radio-options"> 
                        { selectedOption && selectedOption.toLowerCase() === option.toLowerCase() && <input type="radio" id={option} name={heading} value={option} checked onChange={handleOnChange}></input> }
                        { selectedOption && selectedOption.toLowerCase() !== option.toLowerCase() && <input type="radio" id={option} name={heading} value={option} onChange={handleOnChange}></input> }
                        { !selectedOption && <input type="radio" id={option} name={heading} value={option} onChange={handleOnChange}></input> }            
                        <label for={option}  className="radio-label">{option}</label>
                    </div>
                    })  }
            </div>
            <div className="field-description"><p className="field-description-txt">{description}</p></div>    
        </div>
        
      
    );
  }
  
  export default Radio;