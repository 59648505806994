
import '../Css/VideoSaved.css';
import constants  from '../Utility/constant';

function VideoSaved({screen, setShowVideoDetails, setScreen, video, setEditMode, setShowresult, setCreateNewKeywords}) {

    setEditMode(undefined);
    setShowVideoDetails(undefined);
    setShowresult(undefined);
    setCreateNewKeywords([]);

    
    const goToEditPage = async( ) => {
        setEditMode(undefined)
        setShowVideoDetails(undefined)
        setShowresult(undefined)
        setScreen(constants.EDIT_METADATA)
    }

    return (
        <div className="video-saved-container"> 
            <div className='video-saved-item1'>
                <div className='video-saved-inner-flex'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="59" height="59" viewBox="0 0 59 59" fill="none">
                        <path d="M22.125 29.5L27.0417 34.4167L36.875 24.5833M51.625 29.5C51.625 41.7193 41.7193 51.625 29.5 51.625C17.2807 51.625 7.375 41.7193 7.375 29.5C7.375 17.2807 17.2807 7.375 29.5 7.375C41.7193 7.375 51.625 17.2807 51.625 29.5Z" stroke="#4AA90F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <div className='video-saved-text-flex'>
                        <h3 className='video-saved-text'>{screen===constants.VIDEO_SAVED?'Video has been saved':'Video has been activated'}</h3>
                        <p className='video-saved-desc'>{screen===constants.VIDEO_SAVED?'Metadata has been saved for video "' + video.name + '".' :'Metadata has been saved and activated for video "'+ video.name + '".'}</p>
                    </div>
                </div>
                <div className='brightcove-id-flex'>
                    <div className='brightcove-id-heading'>Brightcove ID</div>
                    <div className='brightcove-id-inner-flex'>
                        <div className='brightcove-id-box'>
                            <div className='brightcove-id'>{video.id}</div>
                            <svg onClick={() => {navigator.clipboard.writeText(video.id);}} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M6 12H4.5C3.67157 12 3 11.3284 3 10.5V4.5C3 3.67157 3.67157 3 4.5 3H10.5C11.3284 3 12 3.67157 12 4.5V6M7.5 15H13.5C14.3284 15 15 14.3284 15 13.5V7.5C15 6.67157 14.3284 6 13.5 6H7.5C6.67157 6 6 6.67157 6 7.5V13.5C6 14.3284 6.67157 15 7.5 15Z" stroke="#0336FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                        <div className='brightcove-id-copy' onClick={() => {navigator.clipboard.writeText(video.id);}}>Copy</div>
                    </div>
                </div>
            </div>
            <div className='video-saved-item2'>
                <p className='video-saved-item2-desc'>To continue working on video metadata and improve discoverability, click on the button below.</p>
                <button className='video-saved-button' onClick={() => {goToEditPage()}}>Edit Another Video’s Metadata</button>
            </div>
        </div>  
    );
  }
  
  export default VideoSaved;