module.exports = Object.freeze({
    OPTIONS : {
        'geo' : [ 'Unrestricted', 'Allow viewing from the following countries', 'Prevent viewing from the following countries']
    },

    SECONDARY_SECTION_MAX_NUMBER : 4,
    TERTIARY_SECTION_MAX_NUMBER : 3 ,
    UNRESTRICTED : 'Unrestricted',
    GEO: 'geo',

    SCHEDULE_WINDOW: 'Start from {0} until {1}',

    EDIT_METADATA : 'Edit-Metadata',
    APPROVE_KEYWORD : 'Approve-Keyword',
    VIEW_METADATA : 'View-Metadata',
    REVIEW_METADATA : 'REVIEW-Metadata',
    VIDEO_SAVED : 'Video-Saved',
    VIDEO_SAVED_ACTIVATED : 'Video-Saved-Activated',

    VIEW_METADATA_DECS : 'This title is locked by another user. The content is read only and you cannot make any changes',
    REVIEW_METADATA_DECS : 'The changes will be effective immediately and any changes to the video assets such as titles and description will apply to other systems.',
    PAGINATION_ITEMS_PER_PAGE : 20,

    //Sorting
    ASC_SORT: 'asc',
    DES_SORT : 'des',

    SORT_VIDEO_TITLE: 'name',
    SORT_SEO_OWNER: 'custom_fields.seo_owner',
    SORT_SOURCE: 'custom_fields.source',
    SORT_AUTHOR: 'custom_fields.author',
    SORT_CREATED_AT: 'created_at',
    NEW_KEYWORD : 'keyword', //need to be changed after receiving the getKeywords api
    VIDEOS_COUNT : 'count', //need to be changed after receiving the getKeywords api
    KEYWORD_VIDEO_TITLE : 'name',
    KEYWORD_VIDEO_BRAND : 'custom_fields.source',
    KEYWORD_VIDEO_SECTION : 'primary',

    //Section dropdown
    MASTHEADS : 'category_masthead',
    OTHERS : 'category_other',
    NEWS : 'sky_category',
    SPORT_KTV : 'category_sport_ktv',

    SEC_OPTS : [ 
        {
            key : 'category_masthead',
            value : 'All Mastheads'
        },
        {
            key : 'category_other',
            value : 'Prestige, travel & food'
        },
        {
            key : 'sky_category',
            value : 'Sky News'
        },
        {
            key : 'category_sport_ktv',
            value : 'Sport KTV'
        }
    ],

    TER_OPTS : [ 
        {
            key : 'category_masthead',
            value : 'All Mastheads'
        },
        {
            key : 'category_other',
            value : 'Prestige, travel & food'
        },
        {
            key : 'sky_category',
            value : 'Sky News'
        }
    ],
    MONTHS: ['January','February','March','April','May','June','July','August','September','October','November','December'],
    SORT_ASC_ORDER : ' *!@_."()#^&%-=+01234567989abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
    SORT_DSC_ORDER : 'ZYXWVUTSRQPONMLKJIHGFEDCBAzyxwvutsrqponmlkjihgfedcba98976543210+=-%&^#)("._@!* ',
    //Idle TimeOut 
    IDLE_TIMEOUT_INTERVAL : 25, //mins
    COUNT_DOWN_INTERVAL : 5, //MINS

    SINGLE_ENTRY_FIELDS : ['source','seo_owner','author','credits','production_type','geo',
    'metadata_content_event', 'metadata_content_subject', 
    'metadata_content_recipe_type', 'metadata_content_cuisine'],
    //Non_custom_fields
    NON_CUSTOM_FIELDS : ['name','geo','schedule','long_description'],
    META_DATA_FIELDS : ['keywords','metadata_content_location',
    'metadata_content_people','metadata_content_organisation',
    'metadata_content_event','metadata_content_subject',
    'metadata_content_main_ingredient','metadata_content_dietary_needs',
    'metadata_content_technique','metadata_content_recipe_type', 'metadata_content_genre'],

    //Edit page input description 
    PRIMARY : 'Sections will drive where this video content can be displayed e.g. video carousels, index pages etc.',
    SECONDARY : 'Select additional sections if you wish to distribute this video content to other brands. You can add multiple secondary sections.',
    TERTIARY : 'Select additional sections if you wish to distribute this video content to other brands. You can add multiple tertiary sections.',
    VIDEO_TITLE : 'A catchy title can help you attract viewers. It’s a good idea to include keywords in your video titles that your audience will use when searching for your type of video.',
    LONG_DESC : 'Keyword-rich descriptions can help viewers find your videos more easily through search. Start the description with an overview of your video and keywords.',
    SOURCE : 'The source of this video-usually your particular digital property. ',
    SEO_OWNER : 'The SEO Owner will drive the canonical URL for the video.',
    AD_CONTROL_TYPE : 'Prevent ads from being played on the video. Sponsored Content will not play a pre-roll ad. Sensitive Content will not have ads and won’t autoplay.',
    AUTHOR : 'The author/producer of this video. Adding an Author will assist in improved Verity reporting on videos. ',
    CREDIT : '',
    LIVE_WORM : 'An interactive worm shows viewer responses to issues discussed in real-time. It appears on or below the video player.',
    CTA_LINK_TEXT: 'The related article title is displayed to viewers.',
    CTA_LINK : 'The viewers can click it to go to the article page.',
    FAIR_DEALING : 'Fair dealing is a user’s right in copyright law that permits the use of copyright work for reporting the news, without permission or payment to the owner. Only use this video in a way that is necessary to report the news and include the video in stories directly related to the video content.',
    PREMIUM_VIDEO : 'Set free or premium status on a video.',
    PRODUCT_TYPE : 'Specific to the Video team for reporting purposes.',
    RESTRICTION : 'Specify any restrictions on usage (e.g. Reuters content)',
    SHARE_TO_MRSS : 'Specify if this video can be shared and included in MRSS feeds for syndication.',
    STREAM_TYPE : 'Specific if this video is a live stream or Video replay, where applicable,',
    EVERGREEN_VIDEO : '',
    VIDEO_TYPE : 'Select the content format of the video, where applicable (e.g. snackable)',
    NOTE_FAIR_DEALING : 'Note: Fair Dealing content will not appear on index pages or on recommended content.',
    KEYWORD : 'Relevant and quality keywords help your video reach the right audience, increase organic traffic and boost viewer trust.',
    LOCATION : 'Select where your video was recorded. Viewers can search for videos by location.',
    PEOPLE : 'Select people relevant to the video content.',
    ORGANIZATION : 'Select organisations relevant to the video content. e.g. Business names, brands',
    EVENTS : 'Select events relevant to the video content. e.g. Mother\'s Day or Federal Elections',
    GENRE : 'Select categories relevant to the video content. e.g. Parenting, Politics',
    SUBJECTS : 'Select subjects relevant to the video content e.g. Movie name, book name or song name',
    RECIPE_TYPE : 'Select recipe keywords relevant to the video content.',
    COOKING_TECH : 'Select cooking techniques relevant to the video content. e.g. BBQ, slow-cook',
    MAIN_INGERDIENT : 'Select ingredients relevant to the video content.',
    CUISINE : 'Select cuisines relevant to the video content.',
    DIETARY_NEEDS : 'Select dietary needs relevant to the video content.',
    GEO_FILTERING : 'Select where you want the video to be available.',
    AVAILABILITY : 'Set a start and end date for any embargoed videos.',

    //Placeholder
    PLACEHOLDER_SOURCE : 'Select a video source',
    PLACEHOLDER_SEO : 'Select a SEO  owner',
    PLACEHOLDER_AUTHOR : 'Search by author name e.g. Nicky Ingham',
    PLACEHOLDER_CREDIT : 'Add credit e.g. “Football” or “Vogue”',
    PLACEHOLDER_CTA_TEXT : 'Add Call to Action Related Link Text',
    PLACEHOLDER_PROD_TYPE : 'Select a production type ',
    PLACEHOLDER_RESTRICTION : 'e.g. Reuters',
    PLACEHOLDER_VIDEO_TYPE : 'Select a video type ',
    PLACEHOLDER_KEYWORD : 'Select or create keywords and keyword phrases',
    PLACEHOLDER_LOCATION : 'Select or create locations e.g. “Melbourne” or “Central Park”',
    PLACEHOLDER_PEOPLE : 'Select or create people e.g. “Anthony Albanese” or “Gordon Ramsay”',
    PLACEHOLDER_ORG : 'Search organisation e.g. "Amazon" or "Gucci"',
    PLACEHOLDER_EVENTS : 'Search or create events e.g. “Mother’s day” or “Federal Election”',
    PLACEHOLDER_GENRE : 'Search or create genre e.g. “Parenting” or “Politics”',
    PLACEHOLDER_SUBJECT : 'Search or create subjects e.g. "Star Wars" or "The Little Prince"',
    PLACEHOLDER_RECIPE : 'Select or create recipe types  e.g. “Lunchbox” or “finger-food”',
    PLACEHOLDER_COOKING_TEC : 'Select or create technique e.g. "air-fry" or "grill"',
    PLACEHOLDER_MAIN_INGREDIENT : 'Select or create main ingredients e.g. "oats" or "apple"',
    PLACEHOLDER_CUISINE : 'Select or create cuisine e.g. “Australian” or “Thai”',
    PLACEHOLDER_DIETARY : 'Select or create dietary needs e.g. “Gluten-free” or “Pescatarian”',

})