import "../Css/Header.css";
import { useState, useEffect } from "react";
import constants from "../Utility/constant";
import { getKeyword } from "../API/Keyword";

const Header = ({
  showAlert,
  editMode,
  setAlert,
  setApproveLoading,
  endOffset,
  initialOffset,
  setCurrentItems,
  isApprover,
  screen,
  setScreen,
  setKeywords,
  user,
  setApprovedKeywords,
}) => {
  const [showLogOut, setShowLogOut] = useState(false);
  const [buttonSelect, setButtonSelect] = useState(constants.EDIT_METADATA);
  const [approveKeywordTriggered, setApproveKeywordTriggered] = useState(false);
  const [logOutTriggered, setLogOutTriggered] = useState(false);

  function editClassName() {
    let editClass = "header-text header-flex ";
    if (isApprover) {
      editClass +=
        buttonSelect === constants.EDIT_METADATA
          ? "header-edit header-button-selected"
          : "header-edit header-button-unselected";
    } else {
      editClass +=
        buttonSelect === constants.EDIT_METADATA
          ? "header-edit-only header-button-selected"
          : "header-edit-only header-button-unselected";
    }
    return editClass;
  }

  function renderEditMetadata() {
    setButtonSelect(constants.EDIT_METADATA);
    setScreen(constants.EDIT_METADATA);
  }
  
  /* eslint-disable */
  async function renderApprover() {
    if (editMode) {
			setApproveKeywordTriggered(true)
			setAlert(true)
		} else {
      setButtonSelect(constants.APPROVE_KEYWORD);
      setScreen(constants.APPROVE_KEYWORD);
      setApproveLoading(true);
      let keywords = await getKeyword();
      let approvedkeywords = keywords.filter((ele) => ele.status === "APPROVED");
      keywords = keywords.filter((ele) => ele.status === "PENDING");
      setApprovedKeywords(approvedkeywords)
      setKeywords(keywords);
      setCurrentItems(keywords.slice(initialOffset, endOffset));
      setApproveLoading(false);
    }
  }

  useEffect(( ) => {
		if(approveKeywordTriggered && !editMode) {
			renderApprover()
			setApproveKeywordTriggered(false)
		}
	}, [approveKeywordTriggered,editMode,renderApprover]);

  useEffect(( ) => {
		if(!showAlert) {
			setLogOutTriggered(false)
			setApproveKeywordTriggered(false)
		}
	}, [showAlert]);

  function renderLogOutButton() {
    setShowLogOut(!showLogOut);
  }

  function logOut() {
    if (editMode) {
			setLogOutTriggered(true)
			setAlert(true)
		} else {
      let logOutUrl = process.env.REACT_APP_LOG_OUT_URL
      window.location.replace(logOutUrl)
    }
  }

  useEffect(( ) => {
		if(logOutTriggered && !editMode) {
			logOut()
			setLogOutTriggered(false)
		}
	}, [logOutTriggered,editMode,logOut]);

  return (
    <header className="header">
      <div className="header-logo"></div>
      <div className="header-logo-text header-text">Australia</div>
      <div
        className={
          isApprover ? "header-button-container" : "header-editonly-container"
        }
      >
        <button className={editClassName()} onClick={renderEditMetadata}>
          Edit Metadata
        </button>
        {isApprover && (
          <button
            className={
              buttonSelect === constants.APPROVE_KEYWORD
                ? "header-approve header-button-selected header-text header-flex"
                : "header-approve header-button-unselected header-text header-flex"
            }
            onClick={renderApprover}
          >
            Approve Keywords
          </button>
        )}
      </div>
      <div className="header-user-container">
        <button
          className="header-user header-text header-flex"
          onClick={renderLogOutButton}
        >
          {" "}
          Hi {user.attributes.firstName.length > 10 ? `${user.attributes.firstName.substring(0, 10)}...` : user.attributes.firstName}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M19 9L12 16L5 9"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        {showLogOut && (
          <button
            className="header-user-logout header-user header-text header-flex"
            onClick={logOut}
          >
            Log out
          </button>
        )}
      </div>
    </header>
  );
};

export default Header;
