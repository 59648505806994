import "../Css/Table.css";
import "../Css/Keyword.css";
import "../Css/ApproveKeyword.css";
import React, { useState, useEffect } from "react";
import { approveKeyword, rejectKeyword, getKeywordById, getKeywordCountById } from "../API/Keyword";

function Keyword({
  bulkApproved,
  bulkRejected,
  edited,
  setEdited,
  object,
  setEditOverlay,
  setVideoOverlay,
  setApproveSuccess,
  checkedKeywords,
  setCheckedKeywords,
  keywords,
  setKeywords,
  setRejectSuccess,
}) {
  const [checked, setChecked] = useState(
    checkedKeywords.find((element) => element.keyword === object.keyword)
  );
  const [count, setCount] = useState("...Loading");
  /* eslint-disable */
  useEffect(() => {
    async function fetchData() {
      let videoCount = await getKeywordCountById(object.keyword);
      if (videoCount !== null || videoCount !== undefined) {
        setCount(`${videoCount} Videos`);
      } else {
        setCount(`${object.count ? object.count : 0} Videos`);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    setChecked(
      checkedKeywords.find((element) => element.keyword === object.keyword)
    );
  }, [checkedKeywords, object.keyword]);

  const check = (keyword) => {
    let array = checkedKeywords;
    let index = array.findIndex(
      (element) => element.keyword === keyword.keyword
    );
    if (index === -1) {
      array.push(keyword);
      setChecked(keyword);
      setCheckedKeywords(array);
    }
  };

  const uncheck = (keyword) => {
    let array = checkedKeywords;
    array = array.filter((element) => element.keyword !== keyword.keyword);
    setChecked(undefined);
    setCheckedKeywords(array);
  };

  const getKeywordDetails = async (keyword) => {
    setVideoOverlay({});
    let keywordDetails = await getKeywordById(keyword);
    setVideoOverlay(keywordDetails ? keywordDetails : undefined);
  };

  const approveSingleKeyword = async (keyword) => {
    await approveKeyword(keyword.keyword);
    let updatedKeywords = keywords;
    keyword.status = "APPROVED";
    let index = updatedKeywords.findIndex(
      (ele) => ele.keyword === keyword.keyword
    );
    if (index > 1) {
      updatedKeywords[index] = keyword;
    }
    setKeywords(updatedKeywords);
    setApproveSuccess(keyword.keyword);
    setEdited(false);
  };

  const editKeyword = async (keyword) => {
    setEditOverlay(keyword);
  };

  const rejectSingleKeyword = async (keyword) => {
    await rejectKeyword(keyword.keyword);
    let updatedKeywords = keywords;
    keyword.status = "REJECTED";
    let index = updatedKeywords.findIndex(
      (ele) => ele.keyword === keyword.keyword
    );
    if (index > 1) {
      updatedKeywords[index] = keyword;
    }
    setKeywords(updatedKeywords);
    setRejectSuccess(keyword.keyword);
    // setRejected(true);
    // setApproved(false);
    setEdited(false);
  };

  return (
    <tr className="table-row">
      <td className="keyword-outer-flex">
        <div className="keyword-name-inner-flex">
          {!checked && (
            <svg
              onClick={() => {
                check(object);
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                x="1"
                y="1"
                width="22"
                height="22"
                rx="3"
                stroke="#404446"
                strokeWidth="2"
              />
            </svg>
          )}
          {checked && (
            <svg
              onClick={() => {
                uncheck(object);
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                x="1"
                y="1"
                width="22"
                height="22"
                rx="3"
                fill="#0228BF"
                stroke="#0228BF"
                strokeWidth="2"
              />
              <path
                d="M17.6341 8L9.84245 15.7917L6.30078 12.25"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
          <div className="keyword-name">
            {object.keyword && object.keyword.length > 50
              ? `${object.keyword.substring(0, 50)}...`
              : object.keyword}
          </div>
        </div>
        <div className="videos-no-flex">
          <button
            onClick={() => {
              getKeywordDetails(object.keyword);
            }}
            className="videos-no"
          >
            {count}
          </button>
          <button
            onClick={() => {
              getKeywordDetails(object.keyword);
            }}
            className="videos-no-icon"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M9 5L16 12L9 19"
                stroke="#0336FF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </td>
      <td className="action-flex">
        { object.status === 'APPROVED' && (
          <div className="status-keyword">
            <svg
              className="status-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {" "}Approved
          </div>
        )}
        { object.status === 'REJECTED' && (
          <div className="status-keyword">
            <svg
              className="status-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M10 14L12 12M12 12L14 10M12 12L10 10M12 12L14 14M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                stroke="#C90000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>{" "}
            Rejected
          </div>
        )}
        { object.status === 'EDITED' && (
          <div className="status-keyword">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M11 5H6C4.89543 5 4 5.89543 4 7V18C4 19.1046 4.89543 20 6 20H17C18.1046 20 19 19.1046 19 18V13M17.5858 3.58579C18.3668 2.80474 19.6332 2.80474 20.4142 3.58579C21.1953 4.36683 21.1953 5.63316 20.4142 6.41421L11.8284 15H9L9 12.1716L17.5858 3.58579Z"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {" "}Edited
          </div>
        )}
        { object.status === 'PENDING' && (
          <svg
            onClick={() => {
              approveSingleKeyword(object);
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
        { object.status === 'PENDING' && (
          <svg
            onClick={() => {
              editKeyword(object);
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M11 5H6C4.89543 5 4 5.89543 4 7V18C4 19.1046 4.89543 20 6 20H17C18.1046 20 19 19.1046 19 18V13M17.5858 3.58579C18.3668 2.80474 19.6332 2.80474 20.4142 3.58579C21.1953 4.36683 21.1953 5.63316 20.4142 6.41421L11.8284 15H9L9 12.1716L17.5858 3.58579Z"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
        { object.status === 'PENDING' && (
          <svg
            onClick={() => {
              rejectSingleKeyword(object);
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M10 14L12 12M12 12L14 10M12 12L10 10M12 12L14 14M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
              stroke="#C90000"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </td>
    </tr>
  );
}

export default Keyword;
