import { deleteSession } from '../API/Auth';

const Alert = ({setAlert, unchangedVideoDetails, setVideo, video, setEditMode }) => {

    window.scrollTo(0,0);
    const onClickYes = async() => {
        setVideo(unchangedVideoDetails)
        await deleteSession(video.id);
        setEditMode(undefined)
        setAlert(undefined);
    }

    const onClickNo = () => {
        setAlert(false);
    }

    return (
        <div className="video-overlay" >
            <div className="editKeyword-card">
                <div className="flex-gap-2">
                    <h3 className="editKeyword-card-heading">
                        Are you sure you want to exit editing mode? Any changes will not be saved.
                    </h3>
                    <div className="editKeyword-button">
                        <button
                            className='editKeyword-confirm-button active-confirm-button'
                            onClick={() => {
                                onClickYes()   
                            }}>
                            Yes
                        </button>
                        <button
                        className="editKeyword-cancel-button"
                        onClick={() => {
                            onClickNo()   
                        }}>
                        No
                        </button>
                    </div>   
                </div>
            </div>

        </div>
    );
};

export default Alert;
