
import '../Css/VideoDetailsCard.css';

import constants  from '../Utility/constant';
import { dateCreated, duration, copyFromExistingVideo } from '../Utility/functions';
import React, { useState, useEffect } from 'react';
import { saveMetadata, getOptions, getItem, getValues } from '../API/CMS';
import Loading from '../Component/Loading';
import { postSession } from '../API/Auth';
import BrightcovePlayer from "@brightcove/react-player-loader";
import { getSession } from '../API/Auth';

const VideoDetailsCard = ({saveSearchResult, existingResult, editMode, setVideo, video, 
    copyVideo, existingVideo, sessionLocked, setLoading, setSessionLocked, importData, 
    setScreen, setShowOverlay, setEditMode, scroll, user}) => {

    const [toolkit, setToolkit] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [state, setState] = useState( video ? video.state : '');
    const [sessionDetails, setSessionDetails] = useState(undefined);

    useEffect(() => {
        if (video) {
            setIsLoading(false)
            setState( video ? video.state : '')
        } else {
            setIsLoading(true)
            setToolkit(false)
        }
    },[video]);

    const cancel = () => {
        setVideo(undefined)
        setShowOverlay(false);
    }

    useEffect(( ) => {
        if(scroll) {
            document.getElementById(scroll)?.scrollIntoView({ behavior: 'smooth' });
        } 
	}, [scroll]);

    const OpenEditing = async(vid) => {
        setLoading(true)
        let sessionLock = await checkSession(vid.id) 
        if(sessionLock) {
            let username = user.firstName + ' ' + user.lastName;
            let session = await postSession(vid.id,  username ? username : "unknown user")
            setSessionDetails(session)
            if(session) {
                let options = await getOptions()
                let items = await getItem()
                let values = await getValues()
                let staticValues = {
                    options : options,
                    items : items,
                    values : values
                }
                setEditMode(staticValues)
            }  
        } 
        setLoading(false)
    }
    
    const copy = async() => {
        let videoDetailsToBeCopied = copyFromExistingVideo(existingVideo,video);
        copyVideo(videoDetailsToBeCopied);
        setShowOverlay(false); 
        await OpenEditing (videoDetailsToBeCopied);
    }

    const checkSession = async(videoId) => {
        let getSessionDetails = await getSession(videoId);
        if(getSessionDetails.length !== 0) {
            let sessionId = sessionDetails ? sessionDetails.id : ''
            let isSessionAlreadyLocked = getSessionDetails[0].id === sessionId
            if (isSessionAlreadyLocked) {
                setSessionLocked(undefined);
                return true
            } else {
                setSessionLocked(getSessionDetails[0]);
                return false
            }
        } else {
            setSessionLocked(undefined);
            return true
        }
    }

    const handleViewOrEditMetadataClick = async() => {
        if(sessionLocked) {
            setScreen(constants.VIEW_METADATA)
            window.scrollTo(0,0);
        }
        else {
            await OpenEditing (video);
        }
        
    }

    const changeState = async() => {     
        let videoDetails = video;
        videoDetails.state = videoDetails.state === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
        let result = await saveMetadata(videoDetails);
        if (!result) {
            setVideo({...video, state: videoDetails.state}) 
            setState(videoDetails.state)
            let dataIndex = existingResult.data.findIndex( ele => ele.id === videoDetails.id );
            if (dataIndex > -1) {
                let updatedData = existingResult.data;
                updatedData[dataIndex] = videoDetails;
                saveSearchResult({...existingResult, data:updatedData})
            }
        }
    }

    const getTitle = () => {
        if(isLoading) {
            return 'Video information'
        } else {
            return 'Selected Video'
        }
    }

    const onClickCopyFromExistingVideo = async () => {
        let sessionLock = await checkSession(video.id) 
        if(sessionLock) {
            setShowOverlay(true);
            setEditMode(undefined);
            window.scrollTo(0,0)
        }
    }

	return (
		<div id="video-details-card" className='video-details-card'>
            <div className='video-details-card-hr'>{getTitle()}</div>
            { isLoading && <Loading></Loading> }
            {   video && <div className='video-details-card-body'>
                <BrightcovePlayer embedType='iframe' attrs={{className: 'video-details-thumbanail'}}  accountId={video.account_id} videoId={video.id} playerId={process.env.REACT_APP_PLAYER_ID} poster={video.images?.thumbnail?.src}/>
                <div className='video-details-outer-container'>
                    { !importData && sessionLocked && <div className='video-details-session-locked-card'>
                        <div className='video-details-session-locked-card-hr'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M8.66667 10.6667H8V8H7.33333M8 5.33333H8.00667M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z" stroke="#011A7F" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <div className='video-details-session-locked-card-hr-text'>This video title is currently locked.</div>
                        </div>
                        <div className='video-details-session-locked-card-body'>{sessionLocked.userName} is currently editing the Metadata for this video title. Editing is disabled, however you can still view the Metadata values. Please note, changes made by {sessionLocked.userName} are not reflected until title is saved.</div>
                    </div> }
                    <div className='video-details-inner-container'>
                        <div className='video-details-row'>
                            <div className='video-details-key'>Brightcove ID</div>
                            <div className='video-details-value'>{video.id}</div>
                        </div>
                        <div className='video-details-row'>
                            <div className='video-details-key'>Video Title</div>
                            <div className='video-details-value video-details-description'>{video.name && video.name.length > 150 ?
                            `${video.name.substring(0, 150)}...` : video.name }</div>
                        </div>
                        <div className='video-details-row'>
                            <div className='video-details-key'>Long Description</div>
                            <div className='video-details-value video-details-description'>{video.long_description && video.long_description.length > 150 ?
                            `${video.long_description.substring(0, 150)}...` : video.long_description }</div>
                        </div>
                        <div className='video-details-row'>
                            <div className='video-details-key'>Date created</div>
                            <div className='video-details-value'>{dateCreated(video.created_at)}</div>
                        </div>
                        <div className='video-details-row'>
                            <div className='video-details-key'>Author</div>
                            <div className='video-details-value'>{video.custom_fields.author}</div>
                        </div>
                        <div className='video-details-row'>
                            <div className='video-details-key'>SEO owner</div>
                            <div className='video-details-value'>{video.custom_fields.seo_owner}</div>
                        </div>
                        <div className='video-details-row'>
                            <div className='video-details-key'>Source</div>
                            <div className='video-details-value'>{video.custom_fields.source}</div>
                        </div>
                        <div className='video-details-row'>
                            <div className='video-details-key'>Duration</div>
                            <div className='video-details-value'>{duration(video.duration)}</div>
                        </div>
                        { importData && <div className='video-details-row'>
                            <div className='video-details-key'>Keywords</div>
                            <div className='video-details-value'>{video.custom_fields.keywords ? video.custom_fields.keywords.toString() : ''}</div>
                        </div>  }
                        { importData && <div className='video-details-row'>
                            <div className='video-details-key'>Genre</div>
                            <div className='video-details-value'>{video.custom_fields.metadata_content_genre}</div>
                        </div>  }
                        <div className='video-details-row'>
                            <div className='video-details-key'>Video Orientation</div>
                            <div className='video-details-value'>{video.custom_fields.orientation}</div>
                        </div>
                        <div className='video-details-row align-center'>
                            <div className='video-details-key'>Status</div>
                            <div className='video-details-value inactive-icon-flex'> 
                                { state === 'ACTIVE'  && <svg className='video-details-icon' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M8.9998 16.2C12.9763 16.2 16.1998 12.9765 16.1998 9.00005C16.1998 5.0236 12.9763 1.80005 8.9998 1.80005C5.02335 1.80005 1.7998 5.0236 1.7998 9.00005C1.7998 12.9765 5.02335 16.2 8.9998 16.2ZM12.3362 7.83644C12.6877 7.48497 12.6877 6.91512 12.3362 6.56365C11.9847 6.21218 11.4149 6.21218 11.0634 6.56365L8.0998 9.52726L6.9362 8.36365C6.58473 8.01218 6.01488 8.01218 5.66341 8.36365C5.31194 8.71512 5.31194 9.28497 5.66341 9.63645L7.46341 11.4364C7.81488 11.7879 8.38473 11.7879 8.7362 11.4364L12.3362 7.83644Z" fill="#009E4F"/>
                                </svg>}
                                { state !== 'ACTIVE' && <span className='inactive-icon'></span> }
                                { state === 'ACTIVE' ? 'Active' : 'Inactive' }
                            </div>
                            { !sessionLocked && <button className={ editMode ? 'video-details-deactivate-button disable' : 'video-details-deactivate-button'} disabled={editMode? true : false} onClick={changeState}>{ state === 'ACTIVE' ? 'Deactivate' : 'Activate' }</button> }
                        </div>
                    </div>
                    { (toolkit && !editMode && !sessionLocked)  && <div className='toolKit-container'>
                        <div className='toolkit'>You can copy metadata from an existing video.</div>
                        <svg width="36" height="4" viewBox="0 0 36 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id=".atom/Tooltip Arrow">
                                <path id="Polygon 1" opacity="0.95" d="M18 4L12 1.04907e-06L24 0L18 4Z" fill="#01050E"/>
                            </g>
                        </svg>
                    </div> }

                    { (!importData && !editMode) && <div className='video-details-button'>
                        <button className='video-details-view-metadata-button' onClick={handleViewOrEditMetadataClick}>{ sessionLocked ? 'View Metadata' : 'Edit Metadata' }</button>
                        { !sessionLocked && <div className='video-details-copy-button-flex'>
                            <button className='video-details-copy-button' onClick={() => {onClickCopyFromExistingVideo()} } >Copy From Existing Title</button>
                            <svg onMouseOver={() => setToolkit(true)} onMouseLeave={() => setToolkit(false)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M13 16H12V12H11M12 8H12.01M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#0228BF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div> }
                    </div> }
                    { importData && <div className='video-details-button'>
                        <button className='video-details-copy-button' onClick={cancel} >Cancel</button>
                        <button className='video-details-view-metadata-button' onClick={copy} >Copy</button> 
                    </div> }    
                </div>  
            </div>  }    
        </div>    
	); 
};
  
  
export default VideoDetailsCard;