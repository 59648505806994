import '../Css/KeyValueDropdown.css';
import constants from "../Utility/constant";
import React, { useState, useRef , useEffect} from 'react';
import { memo } from "react";

function KeyValueDropdown({allOptions, options, value, setValue, secondary,setVideoDetails, suffix, setSecondary}) {

    function getStaticOptions(array) {
      if(suffix===2 || suffix===3) {
        let staticArray = suffix === 3 ? constants.TER_OPTS : constants.SEC_OPTS
        let result = staticArray.filter( ele => array && !array.find( element => element.key === ele.key+"_"+suffix))
        return result
      } else {
        return constants.SEC_OPTS
      }
    }
    const [staticOption, setStaticOption] = useState(getStaticOptions(secondary));
    let selected = value ? constants.SEC_OPTS.find( ele => value.key.includes(ele.key) ) : ''
    const [openKeyDropdown, setOpenKeyDropdown] = useState(false);
    const [openValueDropdown, setOpenValueDropdown] = useState(false);
    const [selectedKey, setSelectedKey] = useState(value ? value.key : '');
    const [selectedKeyValue, setSelectedKeyValue] = useState(selected ? selected.value : '');
    const [selectedValue, setSelectedValue] = useState(value ? value.value : '');
    const [values, setValues] = useState(value && value.option && Array.isArray(value.option.options) ? value.option.options : []);
    const [input, setInput] = useState("");
    const dropMenu = useRef(null)

    useEffect(( ) => {
      if(suffix===2 || suffix===3) {
        let staticArray = suffix === 3 ? constants.TER_OPTS : constants.SEC_OPTS
        let result = staticArray.filter( ele => secondary && !secondary.find( element => element.key === ele.key+"_"+suffix))
        setStaticOption(result)
      } else {
        setStaticOption(constants.SEC_OPTS)
      }
  }, [secondary,suffix]);

    useEffect(( ) => {
      let selected = value ? constants.SEC_OPTS.find( ele => value.key.includes(ele.key) ) : ''
      setSelectedKey(value ? value.key : '')
      setSelectedKeyValue(selected ? selected.value : '')
      setValues(value && value.option && Array.isArray(value.option.options) ? value.option.options : [])
      setSelectedValue(value ? value.value : '')
      
    }, [value]);
    
    const selectKey = (option) => {
        let key = selectedKey
        setSelectedKey(option.key+'_'+suffix);
        setSelectedKeyValue(option.value)
        setSelectedValue('');
        let array = options.find( element => element.keys === option.key+'_'+suffix)
        setValues(array && array.options ? array.options : []) 
        setOpenKeyDropdown(!openKeyDropdown);
        if (suffix===2 || suffix ===3) {
          let updatedArray = secondary
          updatedArray = updatedArray.filter( ele => ele.key !== option.key )
          let updatedIndex = updatedArray.findIndex( ele => ele.key === key )
          if(updatedIndex>-1) {
            updatedArray[updatedIndex] = {
              key: option.key+"_"+suffix,
              option: allOptions.find( element => element.keys === option.key+"_"+suffix)
            }
          }
          setSecondary(updatedArray)
          setStaticOption(getStaticOptions(updatedArray) )
        }
    }
    
    const select = (option) => {
        setSelectedValue(option);
        setOpenValueDropdown(!openValueDropdown);
        setValue(selectedKey,option,suffix)
        setVideoDetails(selectedKey, option);
    }

    function onChangeHandler(event) {
        event.preventDefault();
        setSelectedValue(event.target.value);
        setInput(event.target.value);
        if (event.target.value.length > 2) {
            setOpenValueDropdown(true);
        } else {
            setOpenValueDropdown(false);
        }
    }

    const closeOpenMenus = (e)=>{
      if ( dropMenu && e && dropMenu.current && e.target && dropMenu.current.id!==e.target.id ){
        setOpenKeyDropdown(false)
        setOpenValueDropdown(false)
      }
    }

    document.addEventListener('mousedown',closeOpenMenus)
    
    return (
    <div ref={dropMenu} id={selectedKey} className="primary-section-select">
        <div id={selectedKey} className='select' onClick={() => setOpenKeyDropdown(!openKeyDropdown)}>
            <div id={selectedKey} className='select-input key-select-input'>{selectedKeyValue}</div>
            <span className='custom-arrow-kv'></span>    
        </div>
        { openKeyDropdown && staticOption.length > 0 && <div id={selectedKey} className='dropdown-option-container key-options'>
                { staticOption.map((option) => <div id={selectedKey} className='dropdown-each-option' onClick={()=>{selectKey(option)}}>{option.value}</div> )}
            </div>
        }
        <div id={selectedKey} className='select-value' onClick={() => setOpenValueDropdown(!openValueDropdown)}>
                <div className='select-input value-select-input'> <input
                className="input-disable width-section-input"
                type="dropdown"
                onChange={onChangeHandler}
                value={selectedValue}
                placeholder="Search section e.g. “Football” or “Vogue”"
            ></input>
                </div>
            <span className='custom-arrow-kv'></span>
        </div>
        { ( openValueDropdown && input && values.filter((option) => option.toLowerCase().includes(input.toLowerCase()) ).length > 0 ) && <div id={selectedKey} className='value-options dropdown-option-container '>
        { values.filter((option) => option.toLowerCase().includes(input.toLowerCase()) )
                .map((filteredOption) => (
                  <div
                    id={selectedKey}
                    className="dropdown-each-option"
                    onClick={() => {
                      select(filteredOption);
                    }}
                  >
                    {filteredOption}
                  </div>
                ))}
        </div>}
        { ( openValueDropdown && !input ) && <div id={selectedKey} className='value-options dropdown-option-container '>
        { values.map((option) => <div id={selectedKey} className='dropdown-each-option' onClick={()=>{select(option)}}>{option}</div> )}
        </div>}
    </div>
      
    );
  }
  
  export default memo(KeyValueDropdown);