import '../Css/Loading.css';

function Loading({ className }) {
    return (
      <div className={className ? 'loader approve-loading' : "loader"}>
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <circle cx="20" cy="20" r="18.5" stroke="#EAEBEC" strokeWidth="3"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M37 20.0002C37 29.3891 29.3888 37.0002 20 37.0002V40.0002C31.0457 40.0002 40 31.0459 40 20.0002C40 19.5522 39.9853 19.1075 39.9562 18.6667H36.9485C36.9826 19.1068 37 19.5515 37 20.0002Z" fill="#0228BF"/>
        </svg>
      </div>
      
    );
  }
  
  export default Loading;
  