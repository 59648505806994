import constants from "../Utility/constant";
const host = process.env.REACT_APP_MIDDLEWARE_HOST;
const API_KEY = process.env.REACT_APP_API_KEY;

export async function searchVideos(input,offset,limit) {
    let url = `${host}cms/search?q=name:${input} id:${input} author:${input}&offset=${offset}&limit=${limit}`;
    const headers = { 'x-api-key' : API_KEY };
    const result = await fetch(url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        headers: headers
    }).catch( error => {
        console.log(`Error in searching the video : ` + error.message);
        return undefined
    })
    if( result && result.ok){
        let jsonBody = await result.json()
        console.log(jsonBody)
        return jsonBody
    }else{
        console.log(`Error in searching the video`);
        return undefined
    }
    
}

export async function getVideo(input) {
    let url = host + 'cms/videos/' + input ;
    const headers = { 'x-api-key' : API_KEY };
    const result = await fetch(url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        headers: headers
    }).catch( error => {
        console.log(`Error in getting the video : ` + error.message);
        return undefined
    })
    if( result && result.ok){
        let jsonBody = await result.json()
        console.log(jsonBody)
        return jsonBody
    }else{
        console.log(`Error in getting the video`);
        return undefined
    }
}

export async function saveMetadata(input) {
    let url = host + 'cms/videos/' + input.id ;
    const headers = { 'x-api-key' : API_KEY, 'Content-Type': 'application/json'  };
    const result = await fetch(url, {
        method: 'PATCH', // *GET, POST, PUT, DELETE, etc.
        headers: headers,
        body: JSON.stringify(input)
    }).catch( error => {
        console.log(`Error in updating the metadata : ` + error.message);
        return error.message
    })
    console.log(result)
    if( result && result.ok){
        return false
    }else{
        console.log(`Error in updating the video` );
        return `Error in updating the video`
    }
    
}

export async function getOptions() {
    let url = host + 'cms/fields' ;
    const headers = { 'x-api-key' : API_KEY };
    const result = await fetch(url, {
        method: 'GET', 
        headers: headers,
    }).catch( error => {
        console.log(`Error in reading the options : ` + error.message);
        return undefined
    })
    if( result && result.ok){
        let jsonBody = await result.json()
        return jsonBody
    } else {
        console.log(`Error in reading the options`);
        return undefined
    }
}

export async function getItem() {
    let url = host + 'items' ;
    const headers = { 'x-api-key' : API_KEY };
    const result = await fetch(url, {
        method: 'GET', 
        headers: headers,
    }).catch( error => {
        console.log(`Error in reading the items : ` + error.message);
        return undefined
    })
    if( result && result.ok){
        let jsonBody = await result.json()
        return jsonBody
    } else {
        console.log(`Error in reading the items`);
        return undefined
    }
}

export async function getValues() {
    let fields = await getOptions();
    let keys = [constants.MASTHEADS, constants.OTHERS, constants.NEWS, constants.SPORT_KTV]
    let values = []
    for (let index = 0; index < keys.length; index++) {
        const element = keys[index];
        let primary = {
            keys : element+'_1',
            options: fields.filter(item => item.id === element+'_1').map(item => item.enum_values)[0]
        }
        values.push(primary)
        let secondary = {
            keys : element+'_2',
            options: fields.filter(item => item.id === element+'_2').map(item => item.enum_values)[0]
        }
        values.push(secondary)
        let tertiary = {
            keys : element+'_3',
            options: fields.filter(item => item.id === element+'_3').map(item => item.enum_values)[0]
        }
        values.push(tertiary)    
    }
    return values
}

export async function updateItems(options) {
    let url = host + 'items' ;
    const headers = { 'x-api-key' : API_KEY, 'Content-Type': 'application/json' };
    const result = await fetch(url, {
        method: 'PUT', 
        headers: headers,
        body: JSON.stringify(options)
    }).catch( error => {
        console.log(`Error in reading the items : ` + error.message);
        return undefined
    })
    if( result && result.ok){
        let jsonBody = await result.json()
        return jsonBody
    } else {
        console.log(`Error in reading the items`);
        return undefined
    }
}

export async function updateImage(videoId, input) {
    let url = host + 'cms/images/' + videoId ;
    const headers = { 'x-api-key' : API_KEY, 'Content-Type': 'application/json'  };
    const result = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: headers,
        body: JSON.stringify(input)
    }).catch( error => {
        console.log(`Error in updating the image : ` + error.message);
        return error.message
    })
    console.log(result)
    if( result && result.ok){
        return false
    }else{
        console.log(`Error in updating the image` );
        return `Error in updating the image`
    } 
}


