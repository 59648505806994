import "../Css/Input.css";
import React, { useState } from "react";
function Input({
  placeholder,
  heading,
  description,
  required,
  maxLength,
  value,
  videoDetails,
  setVideoDetails,
}) {
  let key = Object.keys(value)[0];
  const [inputValue, setInputValue] = useState(value[key]);
  function handleOnChange(event) {
    event.preventDefault();
    let text = event.target.value;
    setInputValue(text);
    setVideoDetails(key, text);
  }

  return (
    <div className="input-container">
      <div className="input-container-item">
        <div className="input-container-heading">
          <div>
            <span className="input-container-item-tx">{heading}</span>
            {required && <span className="mandatory">*</span>}
          </div>
          {maxLength && (
            <span className="input-characterLimit-tx">
              {inputValue ? inputValue.length : 0}{ "/" + maxLength}
            </span>
          )}
        </div>
        {required && (
          <input
            id="wordIntent"
            className="title-input"
            value={inputValue}
            onChange={handleOnChange}
            required
            placeholder={placeholder}
            maxLength={maxLength}
          ></input>
        )}
        {!required && (
          <input
            id="wordIntent"
            className="title-input"
            value={inputValue}
            onChange={handleOnChange}
            placeholder={placeholder}
            maxLength={maxLength}
          ></input>
        )}
      </div>
      <div className="field-description">
        <p className="field-description-txt">{description}</p>
      </div>
    </div>
  );
}

export default Input;
