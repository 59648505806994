
import React, { useState, useEffect, useRef } from 'react';
import '../Css/EditCard.css';
import KeyValueDropdown from '../Component/KeyValueDropdown'
import DropDown from "./DropDown";
import Radio from './Radio';
import Input from './Input';
import IdleTimeOutHandler from "./IdleTimeHandler";
import constants from "../Utility/constant";
import { deleteSession } from '../API/Auth';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import { date, getTime } from '../Utility/functions'
import { uploadFile } from '../API/FileUpload'

const EditCard = ( { props }) => {
    /* eslint-disable */
    let staticValues = props.editMode;
    const [videoDetails, setVideoDetails] = useState(props.video);
    const [createNewKeyword, setCreateNewKeyword] = useState({});
    const [requiredField, setRequiredField] = useState(undefined);
    const [longDsec, setLongDes] = useState(videoDetails.long_description);
    const [startDate, setStartDate] = useState(props.video.schedule && props.video.schedule.starts_at ? dayjs(date( props.video.schedule.starts_at)) : null);
    const [endDate, setEndDate] = useState(props.video.schedule && props.video.schedule.ends_at ? dayjs(date(props.video.schedule.ends_at)) : null);
    const [startTime, setStartTime] = useState(props.video.schedule && props.video.schedule.starts_at ? dayjs(getTime(props.video.schedule.starts_at)): null);
    const [endTime, setEndTime] = useState(props.video.schedule && props.video.schedule.ends_at ? dayjs(getTime(props.video.schedule.ends_at)): null);
    const [primary, setPrimary] = useState(assignSection(videoDetails.custom_fields, 1));
    const [secondary, setSecondary] = useState(assignSection(videoDetails.custom_fields, 2));
    const [tertiary, setTertiary] = useState(assignSection(videoDetails.custom_fields, 3));

    const hiddenFileInput = useRef(null);
    const thumbnailFileInput = useRef(null);
    const [posterImg, setPosterImg] = useState(videoDetails?.images?.poster?.src);
    const [thumbImg, setThumbImg] = useState(videoDetails?.images?.thumbnail?.src);

    const [posterUploading, setPosterUploading] = useState(false);
    const [thumbUploading, setThumbUploading] = useState(false);

    useEffect(( ) => {
        if (createNewKeyword.itemName) {
            let createnewKeywords = props.createNewKeywords;
            let index = createnewKeywords.findIndex(ele => ele.itemName === createNewKeyword.itemName);
            if (index === -1) {
                createnewKeywords.push(createNewKeyword);
            } else {
                if (createNewKeyword.itemName === 'keywords') {
                    let updatedOptions = createnewKeywords[index].data;
                    updatedOptions = [...new Set([...updatedOptions, ...createNewKeyword.data])];
                    createnewKeywords[index].data = updatedOptions
                } else {
                    createnewKeywords[index] = createNewKeyword;
                }
            }
            props.setCreateNewKeywords(createnewKeywords);
        }
	}, [createNewKeyword]);

    function onKeyDownHandler(event) {
        if (event.key === "Enter") {
          event.preventDefault();
        }
    }

    document.addEventListener('keydown',onKeyDownHandler)

    const handlePosterChange = async (e) => {
        setPosterUploading(true);
        const file = e.target.files[0];
        try {
           let url = await uploadFile(file)
           if (url) {
                setPosterImg(url)
                setVideoDetails({...videoDetails, images: {
                    ...videoDetails?.images, poster: {
                        ...videoDetails?.images?.poster,  src: url
                } } } )
           }  
        }
        catch (err) {
            console.log(err)
        }    
        setPosterUploading(false);
    };

    const handleClick = event => {
        const { target = {} } = event || {};
        target.value = "";
    };

    const handleThumbnailChange = async (e) => {
        setThumbUploading(true)
        const file = e.target.files[0];
        try {
           let url = await uploadFile(file)
           if (url) {
                setThumbImg(url)
                setVideoDetails({...videoDetails, images: {
                    ...videoDetails?.images, thumbnail: {
                        ...videoDetails?.images?.thumbnail,  src: url
                } } } )
           }  
        }
        catch (err) {
            console.log(err)
        }  
        setThumbUploading(false)
    };

    function assignSection(customField, suffix) {
        let assignSectionOptions = [constants.MASTHEADS, constants.OTHERS, constants.NEWS, constants.SPORT_KTV]
        if ( suffix === 1 ) {
            let updatedValue = null
            assignSectionOptions.forEach( (option, index) => {
                if (customField[`${option}_1`] ) {
                    updatedValue = {
                        key : `${option}_1`,
                        value : customField[`${option}_1`],
                        option : staticValues.values.find( element => element.keys === `${option}_1`)
                    }
                }
                if (index === assignSectionOptions.length - 1 && updatedValue === null) {
                    updatedValue = {
                        key : `${constants.MASTHEADS}_1`,
                        option : staticValues.values.find( element => element.keys === `${constants.MASTHEADS}_1`)
                    }   
                }
            } )  
            return updatedValue
        } else {
            let updatedArray = []
            assignSectionOptions.forEach( (option, index) => {
                if (customField[`${option}_${suffix}`] ) {
                    let addValues = {
                        key : `${option}_${suffix}`,
                        value : customField[`${option}_${suffix}`],
                        option : staticValues.values.find( element => element.keys === `${option}_${suffix}`)
                    }
                    updatedArray.push(addValues)
                }
                if (index === assignSectionOptions.length - 1 && updatedArray.length === 0) {
                    let addValues = {
                        key : `${constants.MASTHEADS}_${suffix}`,
                        option : staticValues.values.find( element => element.keys === `${constants.MASTHEADS}_${suffix}`)
                    }
                    updatedArray.push(addValues)
                }
            } )
            return updatedArray
        }
    }

    const updateSection = (key, value, suffix) => {
        if (suffix === 1) {
            setPrimary({
                key : key,
                value : value,
                option : staticValues.values.find( element => element.keys === key)
            })
        } else {
            let array = suffix === 2 ? secondary : tertiary;
            let updatedIndex = array.findIndex( ele => ele.key === key )
            if(updatedIndex > -1) {
                array[updatedIndex] = {
                    key : key,
                    value : value,
                    option : staticValues.values.find( element => element.keys === key)
                }
            }
            suffix === 2 ? setSecondary(array) : setTertiary(array);
        }
    }

    const updateVideoDetails = (key, value) => {
        let notCustomField = constants.NON_CUSTOM_FIELDS.includes(key)
        if(notCustomField) {
            setVideoDetails({
                ...videoDetails, 
                [key]: value 
            });
        } else {
            setVideoDetails({
                ...videoDetails, 
                custom_fields : {
                    ...videoDetails.custom_fields,
                    [key]: value 
                }
            });
        }     
    }

    const updateDate = (key, value) => {
        let schedule = videoDetails.schedule
        function getGMT (sydneyTimeString) {
            const sydneyTime = new Date(sydneyTimeString);
            const sydneyTimeZoneOffset = 11 * 60 * 60 * 1000;
            const utcTime = new Date(sydneyTime.getTime() - sydneyTimeZoneOffset);
            const formattedGMTTime = utcTime.toUTCString().split(' ');
            let month = utcTime.getUTCMonth()+1
            month = month < 10 ? '0'+month : month
            return formattedGMTTime[3]+'-'+month+'-'+formattedGMTTime[1]+'T'+formattedGMTTime[4]+'Z'
        }
        function getSchedule(dateString, time) {
            let momentTime = new Date(time).toTimeString().split(' ')
            let timeFormat = momentTime[0]
            let date = new Date(dateString)
            let month = date.getMonth()+1
            month = month < 10 ? '0'+month : month
            let day = date.getDate()
            day = day < 10 ? '0'+day : day
            let dateFormat = date.getFullYear()+'-'+month+'-'+day
            return dateFormat + 'T' + timeFormat + 'Z' ;
        }
        if (key === 'startDate') {
            if (value) {
                schedule.starts_at = getGMT(getSchedule(value, startTime))
            } else {
                schedule.starts_at = null
            }
        }
        else if (key === 'startTime') {
            if (!startDate) {
                schedule.starts_at = null
            } else {
                schedule.starts_at = getGMT(getSchedule(startDate, value))
            }
        }
        else if (key === 'endDate') {
            if (!value) {
                schedule.ends_at = null
            } else {
                schedule.ends_at = getGMT(getSchedule(value, endTime))
            }
        }
        else if (key === 'endTime') {
            if (!endDate) {
                schedule.ends_at = null
            } else {
                schedule.ends_at = getGMT(getSchedule(endDate, value))
            }
        }
        setVideoDetails({...videoDetails,schedule: schedule});
        
    }

    useEffect(( ) => {
        if(props.scroll) {
            document.getElementById(props.scroll)?.scrollIntoView({ behavior: 'smooth' });
        } 
	}, [scroll]);

    function getEachFieldOptions(key) {
        if(['source','seo_owner','author','production_type'].includes(key)) {
            let staticKeyProperties = staticValues.options.find( element => element.id === key )
            return staticKeyProperties && Array.isArray(staticKeyProperties.enum_values) ? staticKeyProperties.enum_values : []
        } else {
            let staticKeyProperties = staticValues.items.find( element => element.itemName === key )
            return staticKeyProperties && Array.isArray(staticKeyProperties.data) ? staticKeyProperties.data : []
        }    
    }

    function handleTextAreaChange(event) {
        event.preventDefault()
        let text = event.target.value
        setLongDes(text);
        setVideoDetails({...videoDetails, long_description: text});
    }

    const cancel = async(e ) => {
        e.preventDefault()
        props.setVideo(props.unchangedVideoDetails)
        await deleteSession(props.video.id);
        props.setEditMode(undefined)
    }

    const validateDate = (object) => {
        if(object.schedule) {
            let start = object.schedule.starts_at ? new Date(object.schedule.starts_at) : null
            let end = object.schedule.ends_at ? new Date(object.schedule.ends_at) : null
            if( start && end && end < start) {
                return true
            } else {
                return false
            }
        }
    }

    const review = (e) => {
        e.preventDefault()
        if (!videoDetails.custom_fields.source) {
            setRequiredField('source')
            props.setScroll('source_id')
            return false
        }
        else if (!videoDetails.custom_fields.seo_owner) {
            setRequiredField('seo_owner')
            props.setScroll('seo_owner_id')
            return false
        }
        else if (validateDate(videoDetails)) {
            setRequiredField('schedule')
            return false
        }
        else {
            props.setVideo(videoDetails);
            props.setScreen(constants.REVIEW_METADATA)
            window.scrollTo(0,0);
        }
    }

    const addMoreSec = (suffix) => {
       if (suffix === 2) {
            if (!secondary.find( ele => ele.key === constants.MASTHEADS + '_2')) {
                setSecondary([...secondary,{
                    key : `${constants.MASTHEADS}_2`,
                    option : staticValues.values.find( element => element.keys === `${constants.MASTHEADS}_2`)
                }])
            } else if (!secondary.find( ele => ele.key === constants.OTHERS + '_2')) {
                setSecondary([...secondary,{
                    key : `${constants.OTHERS}_2`,
                    option : staticValues.values.find( element => element.keys === `${constants.OTHERS}_2`)
                }])
            } else if (!secondary.find( ele => ele.key === constants.NEWS + '_2')) {
                setSecondary([...secondary,{
                    key : `${constants.NEWS}_2`,
                    option : staticValues.values.find( element => element.keys === `${constants.NEWS}_2`)
                }])
            } else {
                setSecondary([...secondary,{
                    key : `${constants.SPORT_KTV}_2`,
                    option : staticValues.values.find( element => element.keys === `${constants.SPORT_KTV}_2`)
                }])
            }
       } else {
            if (!tertiary.find( ele => ele.key === constants.MASTHEADS + '_3')) {
                setTertiary([...tertiary,{
                    key : `${constants.MASTHEADS}_3`,
                    option : staticValues.values.find( element => element.keys === `${constants.MASTHEADS}_3`)
                }])
            } else if (!tertiary.find( ele => ele.key === constants.OTHERS + '_3')) {
                setTertiary([...tertiary,{
                    key : `${constants.OTHERS}_3`,
                    option : staticValues.values.find( element => element.keys === `${constants.OTHERS}_3`)
                }])
            } else {
                setTertiary([...tertiary,{
                    key : `${constants.NEWS}_3`,
                    option : staticValues.values.find( element => element.keys === `${constants.NEWS}_3`)
                }])
            } 
       }
    }

    const deleteSec = (value) => {
        let updatedArray = secondary.filter( ele => ele.key !== value.key )
        setSecondary(updatedArray)
        updateVideoDetails(value.key,null)
        
    }

    const deleteTer = (value) => {
        let updatedArray = tertiary.filter( ele => ele.key !== value.key )
        setTertiary(updatedArray)
        updateVideoDetails(value.key,null)
    }

  return (
    
    <form className="edit-card-container" onSubmit={(e)=>{review(e)}} >
        <IdleTimeOutHandler ref={props.idleHandler} id={props.video.id} setMinutes={props.setMinutes} setShowModal={props.setShowModal}  setTimerToast={()=>{props.setIsTimeOut(true)}} onActive={()=>{props.setIsActive(true)}} onIdle={()=>{props.setIsActive(false)}} setEditMode={()=>{props.setEditMode(undefined)}}></IdleTimeOutHandler>
        <div id='assign-section-card' className="assign-section-card">
            <h3 className="assign-section-card-hr">2. Assign Section</h3>
            <div className="assign-section-card-body">
                <div className="primary-section">
                    <div className="primary-section-input">
                        <div><span className="primary-section-input-tx">Primary section</span><span className="mandatory">*</span></div>
                        <KeyValueDropdown suffix={1} setValue={updateSection} options={staticValues.values} value={primary} videoDetails={videoDetails} setVideoDetails={updateVideoDetails} required></KeyValueDropdown>
                    </div>
                    <div className="field-description">
                        <p className="field-description-txt">{constants.PRIMARY}</p>
                    </div>
                    
                </div>
                {  secondary.map ((value, index) => {
                    return <div className="primary-section">
                        <div className="primary-section-input">
                            <div><span className="primary-section-input-tx">Secondary section</span></div>
                            <KeyValueDropdown allOptions={staticValues.values} secondary={secondary} setSecondary={setSecondary} suffix={2} setValue={updateSection} options={staticValues.values}  value={value} videoDetails={videoDetails} setVideoDetails={updateVideoDetails}></KeyValueDropdown>
                            {   secondary.length === index+1 && secondary.length < constants.SECONDARY_SECTION_MAX_NUMBER && <button className="sec-add-section" onClick={() => {addMoreSec(2)}}>
                                <div className="add-section-txt">+  Add more section</div>
                            </button>   }       
                        </div>
                        {  index > 0 && <svg className='delete-icon' onClick={() => {deleteSec(value)}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M19 7L18.1327 19.1425C18.0579 20.1891 17.187 21 16.1378 21H7.86224C6.81296 21 5.94208 20.1891 5.86732 19.1425L5 7M10 11V17M14 11V17M15 7V4C15 3.44772 14.5523 3 14 3H10C9.44772 3 9 3.44772 9 4V7M4 7H20" stroke="#0336FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            }
                        <div className="field-description">
                            <p className="field-description-txt">{constants.SECONDARY}</p>
                        </div>    
                    </div>
                }   )   }
                {   tertiary.map ((value, index) => {
                    return <div className="primary-section">
                        <div className="primary-section-input">
                            <div><span className="primary-section-input-tx">Tertiary section</span></div>
                            <KeyValueDropdown allOptions={staticValues.values} secondary={tertiary} setSecondary={setTertiary}  suffix={3} setValue={updateSection} options={staticValues.values} value={value} videoDetails={videoDetails} setVideoDetails={updateVideoDetails}></KeyValueDropdown>
                            {   tertiary.length === index+1 && tertiary.length < constants.TERTIARY_SECTION_MAX_NUMBER && <button className="sec-add-section" onClick={() => {addMoreSec(3)}}>
                                <div className="add-section-txt">+  Add more section</div>
                            </button>   }       
                        </div>
                        {  index > 0 && <svg className='delete-icon' onClick={() => {deleteTer(value)}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M19 7L18.1327 19.1425C18.0579 20.1891 17.187 21 16.1378 21H7.86224C6.81296 21 5.94208 20.1891 5.86732 19.1425L5 7M10 11V17M14 11V17M15 7V4C15 3.44772 14.5523 3 14 3H10C9.44772 3 9 3.44772 9 4V7M4 7H20" stroke="#0336FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            }
                        <div className="field-description">
                            <p className="field-description-txt">{constants.TERTIARY}</p>
                        </div>    
                    </div>
                }   )   }

            </div>
        </div>

        <div id='general-video-details' className="general-video-details-card">
            <h3 className="assign-section-card-hr">3. General Video Details</h3>
            <div className="general-video-item-1">
                <Input heading={'Video title '} description={constants.VIDEO_TITLE} required={true} maxLength={250} value={{'name': videoDetails.name}} videoDetails={videoDetails} setVideoDetails={updateVideoDetails} ></Input>
                <div className="primary-section"> 
                    <div className="primary-section-input">
                        <div className="desc-textarea-heading">
                            <div><span className="primary-section-input-tx">Long description </span><span className="mandatory">*</span></div>
                            <span className="input-characterLimit-tx">{longDsec ? longDsec.length: 0}{'/'+5000}</span>
                        </div>
                        <textarea className="desc-textarea" maxLength={5000} required onChange={handleTextAreaChange} value={longDsec}></textarea>
                    </div>
                    <div className="field-description">
                        <p className="field-description-txt">{constants.LONG_DESC}</p>
                    </div>
                    
                </div>
            </div>
            <DropDown setCreateNewKeyword={setCreateNewKeyword} heading={'Source '} description={constants.SOURCE} placeholder={constants.PLACEHOLDER_SOURCE} required={true} options={getEachFieldOptions('source')} value={{'source': videoDetails.custom_fields.source}}  videoDetails={videoDetails} setVideoDetails={updateVideoDetails} ></DropDown>
            { requiredField === 'source' && <span id="source_id" className='required-text'>*This field is required</span>}
            <DropDown setCreateNewKeyword={setCreateNewKeyword} heading={'SEO owner '} description={constants.SEO_OWNER} placeholder={constants.PLACEHOLDER_SEO} required={true} options={getEachFieldOptions('seo_owner')} value={{'seo_owner': videoDetails.custom_fields.seo_owner}} videoDetails={videoDetails} setVideoDetails={updateVideoDetails} ></DropDown>
            { requiredField === 'seo_owner' && <span id="seo_owner_id" className='required-text'>*This field is required</span>}
            <Radio defaultValue={'None'} heading= {'Ad-control type '} description={constants.AD_CONTROL_TYPE} options={['Sponsored content','Sensitive content','None']} value={{'ad_control': videoDetails.custom_fields.ad_control}} videoDetails={videoDetails} setVideoDetails={updateVideoDetails} ></Radio>
            <DropDown setCreateNewKeyword={setCreateNewKeyword} heading={'Author '} description={constants.AUTHOR} placeholder={constants.PLACEHOLDER_AUTHOR} options={getEachFieldOptions('author')} value={{'author': videoDetails.custom_fields.author}}  videoDetails={videoDetails} setVideoDetails={updateVideoDetails} ></DropDown>
            <Input heading={'Credit'} description={constants.CREDIT} placeholder={constants.PLACEHOLDER_CREDIT} maxLength={1020} value={{'credits': videoDetails.custom_fields.credits}} videoDetails={videoDetails} setVideoDetails={updateVideoDetails} ></Input>
            <Radio heading= {'Live worm'} description={constants.LIVE_WORM} options={['No','Yes']} value={{'live_worm': videoDetails.custom_fields.live_worm}} videoDetails={videoDetails} setVideoDetails={updateVideoDetails} ></Radio>
            <Input heading={'Call to Action Related Link Text '} description={constants.CTA_LINK_TEXT} required={false} maxLength={25} value={{'call_to_action_related_link_text': videoDetails.custom_fields.call_to_action_related_link_text}} videoDetails={videoDetails} setVideoDetails={updateVideoDetails} placeholder={'Add Call to Action Related Link Text'}></Input>
            <Input heading={'Call to Action Related Link URL '} description={constants.CTA_LINK} required={false} maxLength={255} value={{'call_to_action_related_link_url': videoDetails.custom_fields.call_to_action_related_link_url}} videoDetails={videoDetails} setVideoDetails={updateVideoDetails}></Input>
            <Radio heading= {'Is this video used for fair dealing?'} description={constants.FAIR_DEALING} options={['No','Yes']} note={constants.NOTE_FAIR_DEALING} value={{'fairdealing': videoDetails.custom_fields.fairdealing}} videoDetails={videoDetails} setVideoDetails={updateVideoDetails} ></Radio>
            <Radio heading= {'Is this a premium video?'} description={constants.PREMIUM_VIDEO}  options={['Free','Premium']} value={{'premium_video': videoDetails.custom_fields.premium_video}} videoDetails={videoDetails} setVideoDetails={updateVideoDetails} ></Radio>
            <DropDown setCreateNewKeyword={setCreateNewKeyword} heading={'Production Type'} description={constants.PRODUCT_TYPE} placeholder={constants.PLACEHOLDER_PROD_TYPE} value={{'production_type': videoDetails.custom_fields.production_type}} videoDetails={videoDetails} setVideoDetails={updateVideoDetails} options={getEachFieldOptions('production_type')} ></DropDown>
            <Input heading={'Restriction '} description={constants.RESTRICTION} required={false} maxLength={1020} value={{'restrictions': videoDetails.custom_fields.restrictions}} videoDetails={videoDetails} setVideoDetails={updateVideoDetails} placeholder={'e.g. Reuters'}></Input>
            <Radio heading= {'Share to MRSS'} description={constants.SHARE_TO_MRSS} options={['No','Yes']} value={{'share_to_mrss': videoDetails.custom_fields.share_to_mrss}} videoDetails={videoDetails} setVideoDetails={updateVideoDetails} ></Radio>
            <Radio defaultValue={'None'} heading= {'Stream type'} description={constants.STREAM_TYPE} options={['Live stream', 'Video replay','None']} value={{'stream_type': videoDetails.custom_fields.stream_type}} videoDetails={videoDetails} setVideoDetails={updateVideoDetails} ></Radio>
            <Radio heading= {'Is this an evergreen video?'} description={constants.EVERGREEN_VIDEO} options={['No','Yes']} value={{'evergreen_content': videoDetails.custom_fields.evergreen_content}} videoDetails={videoDetails} setVideoDetails={updateVideoDetails} ></Radio>
            <DropDown setCreateNewKeyword={setCreateNewKeyword} heading={'Video type'} description={constants.VIDEO_TYPE} placeholder={constants.PLACEHOLDER_VIDEO_TYPE} value={{'video_type': videoDetails.custom_fields.video_type}} videoDetails={videoDetails} setVideoDetails={updateVideoDetails} options={getEachFieldOptions('video_type')} ></DropDown>

        </div>

        <div className="Edit-poster-thumbnail-card" id='Edit-poster-thumbnail-card'>
            <div className="edit-poster-card-header">
                <h3 className="assign-section-card-hr">4. Edit Poster & Thumbnail</h3>
                <p className="edit-poster-card-para">You can add or replace video poster and thumbnail. For best results, use JPG or PNG format with a minimum width of 640px for poster and 160px for thumbnails. Aspect ratio should match the video, generally 16:9 or 4:3.</p>
            </div>
            <div className="edit-poster-card-body">
                <div className="edit-poster-innercontainer">
                    <div className="edit-poster-innerheading">Poster (minimum width = 640px) </div>
                    <img className="edit-poster-image" src={posterImg} alt="poster"></img>
                    <input type="file" onClick={handleClick} onChange={handlePosterChange} ref={hiddenFileInput} style={{display: 'none'}} />
                    <button type='file' onClick={(e)=>{e.preventDefault() ;hiddenFileInput.current.click()}} className={posterUploading ? "edit-image-button edit-poster-image-button uploading": "edit-image-button edit-poster-image-button"} >{posterUploading ? 'Uploading...' : 'Edit'}</button>
                </div>
                <div className="edit-poster-innercontainer">
                    <div className="edit-poster-innerheading">Thumbnail (minimum width = 160px)</div>
                    <img className="edit-thumbnail-image" src={thumbImg} alt="thumbnail"></img>
                    <input type="file" onClick={handleClick} onChange={handleThumbnailChange} ref={thumbnailFileInput}  style={{display: 'none'}} />
                    <button type='file' onClick={(e)=>{e.preventDefault() ;thumbnailFileInput.current.click()}} className={ thumbUploading ? "edit-image-button edit-thumbnail-image-button thumb-uploading" : "edit-image-button edit-thumbnail-image-button"} >{thumbUploading ? 'Uploading...' : 'Edit'}</button>
                </div>
            </div>

        </div>

        <div id='Add-metadata-card' className="Add-metadata-card">
            <h3 className="assign-section-card-hr">5. Add Metadata</h3>
            <DropDown setCreateNewKeyword={setCreateNewKeyword} heading={'Keywords & keyword phrases'} description={constants.KEYWORD} placeholder={constants.PLACEHOLDER_KEYWORD} maxLength={1020} value={{'keywords': videoDetails.custom_fields.keywords}}  videoDetails={videoDetails} setVideoDetails={updateVideoDetails} options={getEachFieldOptions('keywords')} optionKey={'keywords'}></DropDown>
            <DropDown setCreateNewKeyword={setCreateNewKeyword} heading={'Locations'} description={constants.LOCATION} placeholder={constants.PLACEHOLDER_LOCATION} maxLength={1020} value={{'metadata_content_location': videoDetails.custom_fields.metadata_content_location}}  videoDetails={videoDetails} setVideoDetails={updateVideoDetails} options={getEachFieldOptions('locations')} optionKey={'locations'}></DropDown>
            <DropDown setCreateNewKeyword={setCreateNewKeyword} heading={'People'} description={constants.PEOPLE} placeholder={constants.PLACEHOLDER_PEOPLE} maxLength={1020} value={{'metadata_content_people': videoDetails.custom_fields.metadata_content_people}} videoDetails={videoDetails} setVideoDetails={updateVideoDetails} options={getEachFieldOptions('people')} optionKey={'people'}></DropDown>
            <DropDown setCreateNewKeyword={setCreateNewKeyword} heading={'Organisation'} description={constants.ORGANIZATION} placeholder={constants.PLACEHOLDER_ORG} maxLength={1020} value={{'metadata_content_organisation': videoDetails.custom_fields.metadata_content_organisation}} videoDetails={videoDetails} setVideoDetails={updateVideoDetails} options={getEachFieldOptions('organization')} optionKey={'organization'}></DropDown>
            <DropDown setCreateNewKeyword={setCreateNewKeyword} heading={'Events'} description={constants.EVENTS} placeholder={constants.PLACEHOLDER_EVENTS} maxLength={1020} value={{'metadata_content_event': videoDetails.custom_fields.metadata_content_event}} videoDetails={videoDetails} setVideoDetails={updateVideoDetails} options={getEachFieldOptions('events')} optionKey={'events'} ></DropDown>
            <DropDown setCreateNewKeyword={setCreateNewKeyword} heading={'Genre'} description={constants.GENRE} placeholder={constants.PLACEHOLDER_GENRE} maxLength={1020} value={{'metadata_content_genre': videoDetails.custom_fields.metadata_content_genre}} videoDetails={videoDetails} setVideoDetails={updateVideoDetails} options={getEachFieldOptions('genre')} optionKey={'genre'} ></DropDown>
            <DropDown setCreateNewKeyword={setCreateNewKeyword} heading={'Subjects'} description={constants.SUBJECTS} placeholder={constants.PLACEHOLDER_SUBJECT} maxLength={1020} value={{'metadata_content_subject': videoDetails.custom_fields.metadata_content_subject}} videoDetails={videoDetails} setVideoDetails={updateVideoDetails} options={getEachFieldOptions('subjects')} optionKey={'subjects'} ></DropDown>
            <DropDown setCreateNewKeyword={setCreateNewKeyword} heading={'Recipe Type'} description={constants.RECIPE_TYPE} placeholder={constants.PLACEHOLDER_RECIPE} maxLength={1020} value={{'metadata_content_recipe_type': videoDetails.custom_fields.metadata_content_recipe_type}} videoDetails={videoDetails} setVideoDetails={updateVideoDetails} options={getEachFieldOptions('recipe_type')} optionKey={'recipe_type'} ></DropDown>
            <DropDown setCreateNewKeyword={setCreateNewKeyword} heading={'Cooking Technique'} description={constants.COOKING_TECH} placeholder={constants.PLACEHOLDER_COOKING_TEC} maxLength={1020} value={{'metadata_content_technique': videoDetails.custom_fields.metadata_content_technique}} videoDetails={videoDetails} setVideoDetails={updateVideoDetails} options={getEachFieldOptions('cooking_technique')} optionKey={'cooking_technique'} ></DropDown>
            <DropDown setCreateNewKeyword={setCreateNewKeyword} heading={'Main Ingredients'} description={constants.MAIN_INGERDIENT} placeholder={constants.PLACEHOLDER_MAIN_INGREDIENT} maxLength={1020} value={{'metadata_content_main_ingredient': videoDetails.custom_fields.metadata_content_main_ingredient}} videoDetails={videoDetails} setVideoDetails={updateVideoDetails} options={getEachFieldOptions('main_ingredients')} optionKey={'main_ingredients'} ></DropDown>
            <DropDown setCreateNewKeyword={setCreateNewKeyword} heading={'Cuisine'} description={constants.CUISINE} placeholder={constants.PLACEHOLDER_CUISINE} maxLength={1020} value={{'metadata_content_cuisine': videoDetails.custom_fields.metadata_content_cuisine}} videoDetails={videoDetails} setVideoDetails={updateVideoDetails} options={getEachFieldOptions('cuisine')} optionKey={'cuisine'} ></DropDown>
            <DropDown setCreateNewKeyword={setCreateNewKeyword} heading={'Dietary Needs / Diet'} description={constants.DIETARY_NEEDS} placeholder={constants.PLACEHOLDER_DIETARY} maxLength={1020} value={{'metadata_content_dietary_needs': videoDetails.custom_fields.metadata_content_dietary_needs}} videoDetails={videoDetails} setVideoDetails={updateVideoDetails} options={getEachFieldOptions('dietry_needs')} optionKey={'dietry_needs'} ></DropDown>
        </div>

        <div id='publishing-settings' className="publishing-settings-card">
            <h3 className="assign-section-card-hr">6. Publishing Settings</h3>
            <DropDown setCreateNewKeyword={setCreateNewKeyword}  heading={'Geo filtering'} description={constants.GEO_FILTERING} value={{'geo': videoDetails.geo}} videoDetails={videoDetails} setVideoDetails={updateVideoDetails} options={constants.OPTIONS.geo} countries={getEachFieldOptions('country_code')} placeholder={constants.UNRESTRICTED} ></DropDown>
            <Radio heading= {'Availability'} description={constants.AVAILABILITY} options={['Available immediately','Scheduled']} value={{'schedule': videoDetails.schedule}} videoDetails={videoDetails} setVideoDetails={updateVideoDetails} ></Radio>
            { videoDetails.schedule &&
                <div className='edit-poster-card-header'>
                    <div className='start_date_Text'>Start date</div>
                    <div className='start_date_flex'>
                        <LocalizationProvider  dateAdapter={AdapterDayjs}>
                            <DatePicker 
                            label={startDate !== null ? "" : "No start date"}
                            renderInput={(params) => <TextField {...params} />}
                            slotProps={{ textField: {
                                    error: false,
                                    fullWidth: true
                            } }} 
                            sx={{'& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#B2B7BD',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#B2B7BD',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#B2B7BD',
                                },
                            },}}
                            format="DD/MM/YYYY" value={startDate} onChange={(newValue) => { setStartDate(newValue); updateDate('startDate',newValue); } } />
                            <TimePicker label={startTime !== null ? "" : "No start time"}
                            renderInput={(params) => <TextField {...params} />}
                            slotProps={{ textField: {
                                    error: false,
                                    fullWidth: true
                            } }} 
                            sx={{'& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#B2B7BD',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#B2B7BD',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#B2B7BD',
                                },
                            },}}
                            format="hh:mm" value={startTime} onChange={(newValue) => {setStartTime(newValue); updateDate('startTime',newValue); } } />
                        </LocalizationProvider>
                    </div>    
                </div>
            }
            { videoDetails.schedule &&
                <div className='edit-poster-card-header'>
                    <div className='start_date_Text'>End date</div>
                    <div className='start_date_flex'>
                        <LocalizationProvider className='start-date'  dateAdapter={AdapterDayjs}>
                            <DatePicker label={endDate !== null ? "" : "No End date"}
                            renderInput={(params) => <TextField {...params} />}
                            slotProps={{ textField: {
                                    error: false,
                                    fullWidth: true
                            } }} 
                            sx={{'& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#B2B7BD',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#B2B7BD',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#B2B7BD',
                                },
                            },}} 
                            format="DD/MM/YYYY" value={endDate} onChange={(newValue) => {setEndDate(newValue); updateDate('endDate',newValue);}} />
                            <TimePicker label={endTime !== null ? "" : "No End time"}
                            renderInput={(params) => <TextField {...params} />}
                            slotProps={{ textField: {
                                    error: false,
                                    fullWidth: true
                            } }} 
                            sx={{'& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#B2B7BD',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#B2B7BD',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#B2B7BD',
                                },
                            },}} format="hh:mm" value={endTime} onChange={(newValue) =>  {setEndTime(newValue); updateDate('endTime',newValue);}} />
                        </LocalizationProvider>    
                    </div>
                </div>
            }      
            { requiredField === 'schedule' && <span className='required-text'>*End date cannot be before Start date</span>} 
        </div>
        <div className="action-card">
            <button className="action-card-cancel" type='cancel' onClick={(e)=>{cancel(e)}} >Cancel</button>
            <button className="action-card-submit" type='submit' >Review Changes</button>
        </div>
      
      
    </form>
    
  );
};

export default EditCard;