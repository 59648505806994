import "../Css/ApproveKeyword.css";
import "../Css/Table.css";
import React, { useState, useEffect } from "react";
import Keyword from "../Component/Keyword";
import { ascSort, desSort, assignSortKeywordType } from "../Utility/functions";
import { approveKeyword, rejectKeyword } from "../API/Keyword";
import constants from "../Utility/constant";
import Loading from "../Component/Loading";

function ApproveKeyword({
  approveLoading,
  initialOffset,
  endOffset,
  setInitialOffset,
  setEndOffset,
  currentItems,
  setCurrentItems,
  keywords,
  setKeywords,
  setVideoOverlay,
  setEditOverlay,
  edited,
  setEdited,
  approveSuccess,
  setApproveSuccess,
  approvedKeywords,
}) {
  const [rejectSuccess, setRejectSuccess] = useState(undefined);
  const [bulkAction, setBulkAction] = useState(undefined);
  const [checkedKeywords, setCheckedKeywords] = useState([]);
  const [sorting, setSorting] = useState(assignSortKeywordType());
  const [searchInput, setSearchInput] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [leftArrowClass, setLeftArrow] = useState("pagination-left-arrow");
  const [rightArrowClass, setRightArrow] = useState("pagination-right-arrow");
  const [bulkApproved, setBulkApproved] = useState([]);
  const [bulkRejected, setBulkRejected] = useState([]);
  const [showExitOnSearch, setShowExitOnSearch] = useState(false);
  const [inputClass, setInputClass] = useState("keyword-input-container");

  function handleOnChange(event) {
    event.preventDefault();
    setSearchInput(event.target.value); //frame this based on input
  }
  /* eslint-disable */
  useEffect(() => {
    if (searchInput.length > 2) {
      setInputClass("card-input-active keyword-input-container");
      let array = approvedKeywords.filter(
        (ele) => ele && ele.keyword && ele.keyword.includes(searchInput)
      );
      if (array.length > 0) {
        setShowOptions(array);
      } else {
        setShowOptions(undefined);
      }
    } else {
      setInputClass("keyword-input-container");
      setShowOptions(undefined);
    }
    if (searchInput.length >= 1) {
      setShowExitOnSearch(true);
    } else {
      setShowExitOnSearch(false);
    }
  }, [searchInput.length]);

  useEffect(() => {
    if (initialOffset <= 0) {
      setLeftArrow("pagination-left-arrow arrow-disabled");
    } else {
      setLeftArrow("pagination-left-arrow arrow-enabled");
    }
    if (endOffset >= keywords.length) {
      setRightArrow("pagination-right-arrow arrow-disabled");
    } else {
      setRightArrow("pagination-right-arrow arrow-enabled");
    }
  });

  const sort = (key) => {
    if (sorting[key] === constants.ASC_SORT) {
      let sortedData = ascSort(keywords, key);
      setKeywords(sortedData);
      setCurrentItems(sortedData.slice(initialOffset, endOffset));
      let sortType = sorting;
      sortType[key] = constants.DES_SORT;
      setSorting(sortType);
    } else {
      let sortedData = desSort(keywords, key);
      setKeywords(sortedData);
      setCurrentItems(sortedData.slice(initialOffset, endOffset));
      let sortType = sorting;
      sortType[key] = constants.ASC_SORT;
      setSorting(sortType);
    }
  };

  const bulkAccept = async () => {
    let input = []
    checkedKeywords.forEach((element) => {
      if (!["APPROVED", "REJECTED", "EDITED"].includes(element.status)) {
        return input.push(element.keyword);
      }
    });
    await approveKeyword(input.toString());
    for (let index = 0; index < currentItems.length; index++) {
      const element = currentItems[index];
      if (input.includes(element.keyword)) {
        currentItems[index].status = "APPROVED";
      }
    }
    for (let index = 0; index < checkedKeywords.length; index++) {
      const element = checkedKeywords[index];
      if (input.includes(element.keyword)) {
        checkedKeywords[index].status = "APPROVED";
      }
    }
    setEdited(undefined);
    setApproveSuccess(input.toString());
    setBulkApproved(input);
  };

  const bulkReject = async () => {
    let input = [];
    checkedKeywords.forEach((element) => {
      if (!["APPROVED", "REJECTED", "EDITED"].includes(element.status)) {
        input.push(element.keyword);
      }
    });
    await rejectKeyword(input.toString());
    for (let index = 0; index < currentItems.length; index++) {
      const element = currentItems[index];
      if (input.includes(element.keyword)) {
        currentItems[index].status = "REJECTED";
      }
    }
    for (let index = 0; index < checkedKeywords.length; index++) {
      const element = checkedKeywords[index];
      if (input.includes(element.keyword)) {
        checkedKeywords[index].status = "REJECTED";
      }
    }
    setRejectSuccess(input.toString());
    setBulkRejected(input);
  };

  const leftClick = () => {
    if (initialOffset > 0) {
      let initial = initialOffset - constants.PAGINATION_ITEMS_PER_PAGE;
      let result = keywords.slice(initial, initialOffset);
      setCurrentItems(result);
      setEndOffset(initialOffset);
      setInitialOffset(initial);
    }
  };

  const rightClick = () => {
    if (endOffset <= keywords.length) {
      let end = endOffset + constants.PAGINATION_ITEMS_PER_PAGE;
      let result = keywords.slice(endOffset, end);
      setCurrentItems(result);
      setInitialOffset(endOffset);
      setEndOffset(end);
    }
  };

  return (
    <div>
      {approveLoading && <Loading className={"approve-loading"}></Loading>}
      {!approveLoading && (
        <div className="approve-keyword-container">
          <div className="approve-keyword">
            <h2 className="approve-keyword-heading">
              New Keywords for Approval
            </h2>
            {approveSuccess && (
              <div className="approved-success-div">
                <div className="approved-success">
                  {!edited && (
                    <span className="body-base-black">
                      Keyword{" "}
                      <span className="body-base-bold-black">
                        {approveSuccess && approveSuccess.length > 100 ? `‘${approveSuccess.substring(0, 100)}...’` : `‘${approveSuccess}’` }
                      </span>{" "}
                      has been approved.
                    </span>
                  )}
                  {edited && (
                    <span className="body-base-black">
                      Keyword{" "}
                      <span className="body-base-bold-black">
                        {approveSuccess && approveSuccess.length > 50 ? `‘${approveSuccess.substring(0, 50)}...’` : `‘${approveSuccess}’` }
                      </span>{" "}
                      has been edited to{" "}
                      <span className="body-base-bold-black">
                      {edited.keyword && edited.keyword.length > 50 ? `‘${edited.keyword.substring(0, 50)}...’` : `‘${edited.keyword}’` }
                      </span>{" "}
                      from {edited.count} video.
                    </span>
                  )}
                  <svg
                    onClick={() => {
                      setApproveSuccess(undefined);
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M6 18L18 6M6 6L18 18"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            )}
            {rejectSuccess && (
              <div className="approved-success-div bulk-reject-container">
                <div className="approved-success">
                  <span className="body-base-black">
                    Keyword{" "}
                    <span className="body-base-bold-black">
                      {rejectSuccess && rejectSuccess.length > 100 ? `‘${rejectSuccess.substring(0, 100)}...’` : `‘${rejectSuccess}’` }
                    </span>{" "}
                    has been rejected.
                  </span>
                  <svg
                    onClick={() => {
                      setRejectSuccess(undefined);
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M6 18L18 6M6 6L18 18"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            )}
            <div className="approve-keyword-table-flex">
              <div className="approve-keyword-count-flex">
                <div className="approve-keyword-count">
                  {keywords.length} new keywords
                </div>
                <div className="bulk-action-flex">
                  <button
                    onClick={() => {
                      setBulkAction(!bulkAction);
                    }}
                    className="bulk-action-txt"
                  >
                    Bulk Actions
                    {!bulkAction && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M14.25 6.75L9 12L3.75 6.75"
                          stroke="#0336FF"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                    {bulkAction && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M3.75 11.25L9 6L14.25 11.25"
                          stroke="#0336FF"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    )}
                  </button>
                </div>
              </div>
              {bulkAction && (
                <div className="bulk-action-container">
                  <div
                    className="bulk-approve-inner body-base-black"
                    onClick={() => {
                      bulkAccept();
                    }}
                  >
                    Bulk Approve
                  </div>
                  <div className="seperator"></div>
                  <div
                    className="bulk-approve-inner body-base-black"
                    onClick={() => {
                      bulkReject();
                    }}
                  >
                    Bulk Reject
                  </div>
                </div>
              )}

              <table className="approve-keyword-table">
                <tbody>
                  <tr className="header-row ">
                    <th className="heading-txt-flex keyword-name-heading">
                      {(checkedKeywords.length === 0 ||
                        keywords.length !== checkedKeywords.length) && (
                        <svg
                          onClick={() => {
                            setCheckedKeywords(keywords);
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect
                            x="1"
                            y="1"
                            width="22"
                            height="22"
                            rx="3"
                            stroke="#404446"
                            strokeWidth="2"
                          />
                        </svg>
                      )}
                      {keywords.length === checkedKeywords.length && (
                        <svg
                          onClick={() => {
                            setCheckedKeywords([]);
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect
                            x="1"
                            y="1"
                            width="22"
                            height="22"
                            rx="3"
                            fill="#0228BF"
                            stroke="#0228BF"
                            strokeWidth="2"
                          />
                          <path
                            d="M17.6341 8L9.84245 15.7917L6.30078 12.25"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}

                      <div className="heading-flex">
                        <div className="heading-txt">New Keywords</div>
                        <svg
                          onClick={() => {
                            sort(constants.NEW_KEYWORD);
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M12.6673 9.33333L8.00065 14M8.00065 14L3.33398 9.33333M8.00065 14L8.00065 2"
                            stroke="black"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </th>
                    <th className="heading-flex video-heading">
                      <div className="heading-txt"># of Videos</div>
                      <svg
                        onClick={() => {
                          sort(constants.VIDEOS_COUNT);
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M12.6673 9.33333L8.00065 14M8.00065 14L3.33398 9.33333M8.00065 14L8.00065 2"
                          stroke="black"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </th>
                    <th className="heading-flex heading-txt action-heading">
                      Actions
                    </th>
                  </tr>
                  {currentItems.map((object) => (
                    <Keyword
                      bulkApproved={bulkApproved}
                      bulkRejected={bulkRejected}
                      edited={edited && edited.id === object.id ? true : false}
                      setEdited={setEdited}
                      object={object}
                      setEditOverlay={setEditOverlay}
                      setVideoOverlay={setVideoOverlay}
                      setApproveSuccess={setApproveSuccess}
                      checkedKeywords={checkedKeywords}
                      setCheckedKeywords={setCheckedKeywords}
                      keywords={keywords}
                      setKeywords={setKeywords}
                      setRejectSuccess={setRejectSuccess}
                    ></Keyword>
                  ))}
                </tbody>
              </table>
              <div className="approve-keyword-pagination">
                <span
                  className={leftArrowClass}
                  onClick={() => {
                    leftClick();
                  }}
                ></span>
                <div className="pagination-row">
                  <div className="pagination-column pagination-column-1">
                    {keywords.length > 0 ? initialOffset + 1 : 0}-
                    {endOffset > keywords.length
                      ? keywords.length > 0
                        ? keywords.length
                        : 0
                      : endOffset}
                  </div>
                  <div className="pagination-column pagination-column-2">
                    of
                  </div>
                  <div className="pagination-column pagination-column-3">
                    {keywords.length}
                  </div>
                </div>
                <span
                  className={rightArrowClass}
                  onClick={() => {
                    rightClick();
                  }}
                ></span>
              </div>
            </div>
          </div>
          <div className="keyword-search-box">
            <div className="keyword-search-flex">
              <div className="heading-txt">Keywords Glossary</div>
              <div className={inputClass}>
                {inputClass === "keyword-input-container" && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                      stroke="#B2B7BD"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
                {inputClass !== "keyword-input-container" && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10 3.75C6.54822 3.75 3.75 6.54822 3.75 10C3.75 13.4518 6.54822 16.25 10 16.25C13.4518 16.25 16.25 13.4518 16.25 10C16.25 6.54822 13.4518 3.75 10 3.75ZM2.25 10C2.25 5.71979 5.71979 2.25 10 2.25C14.2802 2.25 17.75 5.71979 17.75 10C17.75 14.2802 14.2802 17.75 10 17.75C5.71979 17.75 2.25 14.2802 2.25 10Z"
                      fill="#0336FF"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14.4697 14.4697C14.7626 14.1768 15.2374 14.1768 15.5303 14.4697L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L14.4697 15.5303C14.1768 15.2374 14.1768 14.7626 14.4697 14.4697Z"
                      fill="#0336FF"
                    />
                  </svg>
                )}
                <input
                  type="text"
                  onChange={handleOnChange}
                  value={searchInput}
                  className="keyword-input-txt"
                  placeholder="Search by keyword"
                ></input>
                {showExitOnSearch && (
                  <svg
                    onClick={() => {
                      setSearchInput("");
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L6.53033 18.5303C6.23744 18.8232 5.76256 18.8232 5.46967 18.5303C5.17678 18.2374 5.17678 17.7626 5.46967 17.4697L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967Z"
                      fill="#404446"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z"
                      fill="#404446"
                    />
                  </svg>
                )}
              </div>
              {showOptions && (
                <div className="approve-keyword-search-result">
                  {showOptions.map((each, index) => {
                    if (index % 2 === 1) {
                      return (
                        <div className="approve-keyword-each-search body-small gray-background">
                          {each.keyword}
                        </div>
                      );
                    } else {
                      return (
                        <div className="approve-keyword-each-search body-small">
                          {each.keyword}
                        </div>
                      );
                    }
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ApproveKeyword;
