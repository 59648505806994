import constants from '../Utility/constant';

export function dateCreated(dateString) {
    if (dateString) {
        let date = dateString.split('T')[0].split('-')
        return date[2] + '/' + date[1] + '/' + date[0] 
    } 
    else {
        return ''
    }

}

export function sydneyTime(dateString, isString) {
    const gmtDate = new Date(dateString)
    const utcTime = gmtDate.getTime();
    const sydneyTimeZoneOffset = 11 * 60 * 60 * 1000;
    const sydneyTime = new Date(utcTime + sydneyTimeZoneOffset);
    const formattedSydneyTime = sydneyTime.toISOString(); 
    return isString ? formattedSydneyTime : sydneyTime;
}

export function date(dateString) {
    if (dateString) {
        return sydneyTime(dateString, true).split("T")[0];
    } 
    else {
        return null
    }
}

export function getTime(dateString) {
    if (dateString) {
        let time = sydneyTime(dateString, true).split("T")[1].split(":")
        return sydneyTime(dateString, true).split("T")[0]+"T"+time[0]+':'+time[1] 
    } 
    else {
        return null
    }
}

export function time(duration) {
    
    let minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24),
    am = hours > 12 ? 'PM' : 'AM';
     
    hours = hours > 12 ? hours % 12 : hours;

    hours = (hours !== 0 && hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;

    return hours + ":" + minutes + " " + am;
}

export function duration(duration) {
    let seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = (hours !== 0 && hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return ( hours !== 0 ) ? hours + ":" + minutes + ":" + seconds + " hr" : minutes + ":" + seconds + " min" ;
}

export function availability(schedule) {
    let result = constants.SCHEDULE_WINDOW;
    if (schedule &&( schedule.starts_at || schedule.ends_at)) {
        if  (schedule.starts_at) {
            let start  = dateFormat (schedule.starts_at)
            if (!start.includes('NaN')){
                result = result.replace('{0}', start)
            } else {
                result = result.replace('{0}', 'now')
            }
        } else {
            result = result.replace('{0}', 'now')
        }
        if  (schedule.ends_at) {
            let end  = dateFormat (schedule.ends_at)
            if (!end.includes('NaN')) {
                result = result.replace('{1}', end);
            } else {
                result = result.replace(' until {1}', '');
            }
        } else {
            result = result.replace(' until {1}', '');
        }
        return result === 'Start from now' ? '' : result
    }
    else {
        return ''
    }
} 

export function dateFormat (string) {
    let date = sydneyTime(string, false);
    let dateOfMonth = date.getUTCDate(),
     month = constants.MONTHS[date.getUTCMonth()], 
     year = date.getUTCFullYear(),
     hours = date.getUTCHours(),
     minutes = date.getUTCMinutes(),
     am = hours > 12 ? 'PM' : 'AM';
     hours = hours > 12 ? hours % 12 : hours;
     hours =  (hours < 10) ? "0" + hours : hours;
     minutes = (minutes < 10) ? "0" + minutes : minutes;
    return dateOfMonth + ' ' + month + ' ' + year + ' ' + hours + ':'+ minutes + ' ' + am
}

export function sectionConcate (field) {
    let result = [field['category_masthead_1'],field['sky_category_1'],field['category_Travel_1'],field['category_Prestige_1'],field['category_Food_1'],field['category_sport_ktv_1']]
    return result.join('')
}

export function section(customFields) {
    let dropdown = [ constants.MASTHEADS, constants.OTHERS, constants.NEWS, constants.SPORT_KTV ]
    let primary = null, secondary = [], tertiary = [];
    dropdown.forEach( (value) => {
        if(!primary) {
            primary = customFields[value+'_1']
        }
        secondary.push(customFields[value+'_2'])
        tertiary.push(customFields[value+'_3'])
    } )
    return { primary, secondary, tertiary };
}

export function ascSort(array,key) {
    let comparator = (a,b) => {
        if (a[key] === undefined) {
            return 1;
        }
        if (b[key] === undefined) {
            return -1;
        }
        if(a[key] === b[key]) {
            return 0
        } 
        return a[key] < b[key] ? -1 : 1;       
    }
    if(key === 'name') {
        comparator = (a,b) => {
            let index_a = constants.SORT_ASC_ORDER.indexOf(a[key][0]),
            index_b = constants.SORT_ASC_ORDER.indexOf(b[key][0]);
            if (index_a === index_b) {
                if (a[key] < b[key]) {
                    return -1;
                } else if (a[key] > b[key]) {
                    return 1;
                }
                return 0;
            } else {
                return index_a - index_b;
            }
        }
    }
    else if(key.includes('custom_fields')) {
        key = key.split('.')[1]
        comparator = (a,b) => {
            if (a.custom_fields[key] === undefined) {
                return 1;
            }
            if (b.custom_fields[key] === undefined) {
                return -1;
            }
            if(a.custom_fields[key] === b.custom_fields[key]) {
                return 0
            } 
            return a.custom_fields[key] < b.custom_fields[key] ? -1 : 1;
        }
    } else if (key === constants.KEYWORD_VIDEO_SECTION) {
        comparator = (a,b) => {
            if (sectionConcate(a.custom_fields) === undefined) {
                return 1;
            }
            if (sectionConcate(b.custom_fields) === undefined) {
                return -1;
            }
            if(sectionConcate(a.custom_fields) === sectionConcate(b.custom_fields)) {
                return 0
            } 
            return sectionConcate(a.custom_fields) < sectionConcate(b.custom_fields) ? -1 : 1;
        }
    }
    const strAscending = [...array].sort((a, b) => comparator(a,b) );
  return strAscending
}

export function desSort(array,key) {
    let comparator = (a,b) => {
        if (a[key] === undefined) {
            return 1;
        }
        if (b[key] === undefined) {
            return -1;
        }
        if(a[key] === b[key]) {
            return 0
        } 
        return a[key] < b[key] ? 1 : -1;     
    }
    if(['name'].includes(key)) {
        comparator = (a,b) => {
            let index_a = constants.SORT_DSC_ORDER.indexOf(a[key][0]),
            index_b = constants.SORT_DSC_ORDER.indexOf(b[key][0]);
            if (index_a === index_b) {
                if (a[key] < b[key]) {
                    return 1;
                } else if (a[key] > b[key]) {
                    return -1;
                }
                return 0;
            } else {
                return index_a - index_b;
            }
        }
    } 
    else if(key.includes('custom_fields')) {
        key = key.split('.')[1] 
        if(['source'].includes(key)) {
            comparator = (a,b) => {
                let index_a = constants.SORT_DSC_ORDER.indexOf(a.custom_fields[key][0]),
                index_b = constants.SORT_DSC_ORDER.indexOf(b.custom_fields[key][0]);
                if (index_a === index_b) {
                    if (a.custom_fields[key] < b.custom_fields[key]) {
                        return 1;
                    } else if (a.custom_fields[key] > b.custom_fields[key]) {
                        return -1;
                    }
                    return 0;
                } else {
                    return index_a - index_b;
                }
            }
        } else {
            comparator = (a,b) => {
                if (a.custom_fields[key] === undefined) {
                    return 1;
                }
                if (b.custom_fields[key] === undefined) {
                    return -1;
                }
                if(a.custom_fields[key] === b.custom_fields[key]) {
                    return 0
                } 
                return a.custom_fields[key] < b.custom_fields[key] ? 1 : -1;
            }
        }
    } else if (key === constants.KEYWORD_VIDEO_SECTION) {
        comparator = (a,b) => {
            if (sectionConcate(a.custom_fields) === undefined) {
                return 1;
            }
            if (sectionConcate(b.custom_fields) === undefined) {
                return -1;
            }
            if(sectionConcate(a.custom_fields) === sectionConcate(b.custom_fields)) {
                return 0
            } 
            return sectionConcate(a.custom_fields) < sectionConcate(b.custom_fields) ? 1 : -1;
        }
    }
    const strDescending = [...array].sort((a, b) => comparator(a,b));
  return strDescending
}

export function assignSortType() {
    let sortType = {}
    sortType[constants.SORT_VIDEO_TITLE] = constants.ASC_SORT
    sortType[constants.SORT_SEO_OWNER] = constants.ASC_SORT
    sortType[constants.SORT_SOURCE] = constants.ASC_SORT
    sortType[constants.SORT_AUTHOR] = constants.ASC_SORT
    sortType[constants.SORT_CREATED_AT] = constants.ASC_SORT
    return sortType
}

export function assignSortKeywordType() {
    let sortType = {}
    sortType[constants.NEW_KEYWORD] = constants.ASC_SORT
    sortType[constants.VIDEOS_COUNT] = constants.ASC_SORT
    sortType[constants.KEYWORD_VIDEO_TITLE] = constants.ASC_SORT
    sortType[constants.KEYWORD_VIDEO_BRAND] = constants.ASC_SORT
    sortType[constants.KEYWORD_VIDEO_SECTION] = constants.ASC_SORT
    return sortType
}

export function geoToView(value) {
    if (!value || !value.restricted ) {
        return ['Unrestricted']
    } 
    else if (value && value.exclude_countries ) {
        return ['Prevent viewing from the following countries']
    } else {
        return ['Allow viewing from the following countries']
    }
}


export function viewToGeo(value,countries) {
    if(value === constants.UNRESTRICTED) {
        return null
    }
    else if (value === 'Prevent viewing from the following countries') {
        return {
            restricted : true,
            exclude_countries : true,
            countries : countries
        }
    } else {
        return {
            restricted : true,
            exclude_countries : false,
            countries : countries
        }
    }
}

export function copyFromExistingVideo (existingVideo,video) {
    video.state = existingVideo.state;
    video.id = existingVideo.id;
    video.created_at = existingVideo.created_at;
    video.updated_at = existingVideo.updated_at;
    video.updated_by = existingVideo.updated_by;
    return video
}