import "../Css/DropDown.css";
import React, { useState, useRef } from "react";
import { geoToView, viewToGeo } from "../Utility/functions";
import constants from "../Utility/constant";

function DropDown({
  heading,
  description,
  placeholder,
  options,
  required,
  value,
  optionKey,
  setVideoDetails,
  countries,
  setCreateNewKeyword
}) {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openGEODropdown, setOpenGEODropdown] = useState(false);
  const dropMenu = useRef(null)
  let key = Object.keys(value)[0];
  let valueArray = [];
  if (key === constants.GEO) {
    valueArray = geoToView(value[key]);
  } else {
    valueArray = value[key] ? value[key].split(",") : [];
  }
  if (document.getElementById(key)) {
    document.getElementById(key).getElementsByClassName("input-disable")[0].setAttribute('style', 'width: ' + 46 + 'rem');
  }
  const [selectedOptions, setSelectedOptions] = useState(valueArray);
  const [allowedCountries, setAllowedCountries] = useState(
    value[key] && value[key] !== constants.UNRESTRICTED
      ? value[key].countries
      : []
  );
  const [input, setInput] = useState("");
  const [inputGeo, setInputGeo] = useState("");
  const singleField = constants.SINGLE_ENTRY_FIELDS;

  function onChangeHandler(event, isGeo) {
    event.preventDefault();
    if (isGeo) {
      setInputGeo(event.target.value);
      if (event.target.value.length > 2) {
        setOpenGEODropdown(true);
      }
    } else {
      setInput(event.target.value);
      if (event.target.value.length > 2) {
        setOpenDropdown(true);
      }
    }
  }

  const closeOpenMenus = (e)=>{
    if ( dropMenu && e && dropMenu.current && e.target && dropMenu.current.id!==e.target.id ){
      setOpenDropdown(false)
      setOpenGEODropdown(false)
    }
  }
 
  document.addEventListener('mousedown',closeOpenMenus)

  const openOptions = (event, isGeo) => {
    event.preventDefault();
    let dataValue = event.target.getAttribute("id");
    if (!isGeo && (dataValue !== "unselect" || dataValue !== "unselect-all")) {
      setOpenDropdown(!openDropdown);
    }
    if (isGeo && (dataValue !== "unselect" || dataValue !== "unselect-all")) {
      setOpenGEODropdown(!openGEODropdown);
    }
  };

  const select = async (option, isGeo, createNew) => {
    if (isGeo) {
      if (!allowedCountries.includes(option)) {
        let allowedcountries = [...allowedCountries];
        allowedcountries.push(option);
        setAllowedCountries(allowedcountries);
        setVideoDetails(key, viewToGeo(selectedOptions, allowedcountries));
      }
    } else if (key === constants.GEO) {
      setSelectedOptions([option]);
      if (option === constants.UNRESTRICTED) {
        setVideoDetails(key, {});
      }
    } else {
      if (!singleField.includes(key)) {
        if (!selectedOptions.includes(option)) {
          if (key === 'keywords') {
            let createNewKeywords = option.split(',').map(item => item.trim());
            const selectedoptions = [...new Set([...selectedOptions, ...createNewKeywords])];
            setSelectedOptions(selectedoptions);
            document.getElementById(key).getElementsByClassName("input-disable")[0].setAttribute('style', 'width: 100px');
            setVideoDetails(key, selectedoptions.join(","));
          } else {
            const selectedoptions = [...selectedOptions];
            selectedoptions.push(option);
            setSelectedOptions(selectedoptions);
            document.getElementById(key).getElementsByClassName("input-disable")[0].setAttribute('style', 'width: 100px');
            setVideoDetails(key, selectedoptions.join(","));
          }
        }
      } else {
        setSelectedOptions([option]);
        setVideoDetails(key, option);
      }
    }
    setInput("");
    setInputGeo("");
    setOpenDropdown(false);
    setOpenGEODropdown(false);
    if (createNew) {
      let index = options.indexOf(option);
      if (index === -1) {
        if (optionKey === 'keywords') {
          let createNewKeywords = option.split(',').map(item => item.trim());
          createNewKeywords = createNewKeywords.filter(ele => !options.includes(ele));
          if (createNewKeywords.length > 0) {
            setCreateNewKeyword({ itemName: optionKey, data: createNewKeywords });
          }
        } else {
          options.push(option);
          setCreateNewKeyword({ itemName: optionKey, data: options });
        }
      }
    }
    
  };

  const unselect = (option, isGeo) => {
    if (isGeo) {
      let updated = allowedCountries.filter((ele) => ele !== option);
      setAllowedCountries(updated);
      setVideoDetails(key, viewToGeo(selectedOptions, updated));
    } else if (key === "geo") {
      setSelectedOptions([]);
      setVideoDetails(key, {});
    } else {
      if (document.getElementById(key).getElementsByClassName("selected-options").length === 1) {
        document.getElementById(key).getElementsByClassName("input-disable")[0].setAttribute('style', 'width: ' + 46 + 'rem');
      }
      let updated = selectedOptions.filter((ele) => ele !== option);
      setSelectedOptions(updated);
      if (!singleField.includes(key)) {
        setVideoDetails(key, updated.join(","));
      } else {
        setVideoDetails(key, "");
      }
    }
  };

  const unselectAll = (isGeo) => {
    if (isGeo) {
      setInputGeo("");
    } else {
      document.getElementById(key).getElementsByClassName("input-disable")[0].setAttribute('style', 'width: ' + 46 + 'rem');
      setInput("")
    }
  };

  return (
    <div className="single-dropdown-container">
      <div className="single-dropdown-input">
        <div>
          <span className="single-dropdown-input-tx">{heading}</span>
          {required && <span className="mandatory">*</span>}
        </div>
        <div ref={dropMenu} className="dropdown-style">
          <div id={key} className="single-dropdown">
            <div 
              className="input"
              id="open-option"
              onClick={(event) => {
                openOptions(event);
              }}
            > 
              {selectedOptions &&
                selectedOptions.map((option) => {
                  if (document.getElementById(key)) {
                    document.getElementById(key).getElementsByClassName("input-disable")[0].setAttribute('style', 'width: 100px');
                  }
                  if (option) {
                    return (
                      <div className="selected-options">
                        <div className="selected-options-txt">{option}</div>
                        <svg
                          id="unselect"
                          onClick={() => {
                            unselect(option);
                          }}
                          className="selected-options-x"
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                        >
                          <path
                            d="M3 9L9 3M3 3L9 9"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    );
                  } else {
                    if (document.getElementById(key)) {
                      document.getElementById(key).getElementsByClassName("input-disable")[0].setAttribute('style', 'width: ' + 46 + 'rem');
                    }
                    return <div></div>;
                  }
                })}
              <input
                id={key}
                className="input-disable"
                type="dropdown"
                onChange={onChangeHandler}
                value={input}
                placeholder={
                  selectedOptions && selectedOptions.length === 0
                    ? placeholder
                    : ""
                }
              ></input>
            </div>
            {input.length >= 1  && (
                <svg
                  id="unselect-all"
                  onClick={() => {
                    unselectAll();
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M4.5 13.5L13.5 4.5M4.5 4.5L13.5 13.5"
                    stroke="#404446"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            <svg
              onClick={(event) => {
                openOptions(event);
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M14.25 6.75L9 12L3.75 6.75"
                stroke="#404446"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          {(openDropdown && input && ( options
                  .filter((option) =>
                    option.toLowerCase().includes(input.toLowerCase())
                  ).length > 0 || constants.META_DATA_FIELDS.includes(key) ) && 
            <div id={key} className="dropdown-option-container single-dropdown-option ">
              { options
                  .filter((option) =>
                    option.toLowerCase().includes(input.toLowerCase())
                  )
                  .map((filteredOption) => (
                    <div
                      className="dropdown-each-option"
                      onClick={() => {
                        select(filteredOption);
                      }}
                    >
                      {filteredOption}
                    </div>
                  )
                  )}
              { constants.META_DATA_FIELDS.includes(key) && !options.find((ele) => ele === input) &&
                (
                  <div
                    className="dropdown-each-option "
                    onClick={() => {
                      select(input, false, true);
                    }}
                  >
                    <span className="body-base-black">Create a new </span>
                    <span className="body-base-bold-black create-new-option">
                      {input}
                    </span>
                  </div>
                )}
            </div>
          )}
          {(openDropdown && !input) &&
            <div id={key} className="dropdown-option-container single-dropdown-option ">
              { options.map((option) => (
                  <div
                    className="dropdown-each-option"
                    onClick={() => {
                      select(option);
                    }}
                  >
                    {option}
                  </div>
                )) }
            </div>
          }
        </div>

        {key === constants.GEO && selectedOptions[0] &&
          selectedOptions[0] !== constants.UNRESTRICTED && <div>
            <div id={key} className="geo-countries">
              <div>Countries</div>
              <div className="single-dropdown">
                <div
                  className="input"
                  id="open-option"
                  onClick={(event) => {
                    openOptions(event, true);
                  }}
                >
                  {allowedCountries &&
                    allowedCountries.map((option) => {
                      if (option) {
                        return (
                          <div className="selected-options">
                            <div className="selected-options-txt">{option}</div>
                            <svg
                              id="unselect"
                              onClick={() => {
                                unselect(option, true);
                              }}
                              className="selected-options-x"
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                            >
                              <path
                                d="M3 9L9 3M3 3L9 9"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        );
                      } else {
                        return <div></div>;
                      }
                    })}

                  <input
                    className="input-disable"
                    type="dropdown"
                    onChange={(event) => {
                      onChangeHandler(event, true);
                    }}
                    value={inputGeo}
                    placeholder={
                      selectedOptions.length === 0 ? placeholder : ""
                    }
                  ></input>
                </div>
                { inputGeo.length >= 1 && (
                  <svg
                    id="unselect-all"
                    onClick={() => {
                      unselectAll(true);
                    }}
                    className="unselect-all"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M4.5 13.5L13.5 4.5M4.5 4.5L13.5 13.5"
                      stroke="#404446"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
                <span className="custom-arrow"></span>
              </div>
            </div>
            { ( openGEODropdown && inputGeo && countries
                .filter((option) =>
                  option["alpha-2"]
                    .toLowerCase()
                    .includes(inputGeo.toLowerCase())
                ).length > 0) && 
            <div id={key} className="dropdown-option-container geo-options">
              {countries
                .filter((option) =>
                  option["alpha-2"]
                    .toLowerCase()
                    .includes(inputGeo.toLowerCase())
                )
                .map((filteredOption) => (
                  <div
                    className="dropdown-each-option"
                    onClick={() => {
                      select(filteredOption["alpha-2"], true);
                    }}
                  >
                    {filteredOption["alpha-2"]}
                  </div>
                ))}
            </div>}
            { ( openGEODropdown && !inputGeo) && 
            <div id={key} className="dropdown-option-container geo-options">
              {countries.map((option) => (
                  <div
                    className="dropdown-each-option"
                    onClick={() => {
                      select(option["alpha-2"], true);
                    }}
                  >
                    {option["alpha-2"]}
                  </div>
                ))}
            </div>}
        </div>}
        
       
        
          
      </div>
      <div className="field-description">
        <p className="field-description-txt">{description}</p>
      </div>
    </div>
  );
}

export default DropDown;
