
import React from "react";
import ImportMetadata from '../Screen/ImportMetadata'
import '../Css/Overlay.css'

const Overlay = ( {setSearchResult, searchResult, video, setVideo, setScreen, 
  setShowOverlay, setEditMode, user, setSessionLocked, setIsLoading}) => {
  
  return (
    
    <div className="app-overlay" >
      <ImportMetadata setEditLoading={setIsLoading} setSessionLocked={setSessionLocked} saveSearchResult={setSearchResult} existingResult={searchResult} video={video} setVideo={setVideo} setScreen = { setScreen } 
      setShowOverlay={setShowOverlay} setEditMode={setEditMode} user = {user}></ImportMetadata>  
    </div>
    
  );
};

export default Overlay;

