import "../Css/EditKeywordOverlay.css";
import { updateKeyword } from "../API/Keyword";
import React, { useState, useEffect } from "react";

function EditKeywordOverlay({
  setKeywords,
  setEdited,
  setApproveSuccess,
  editOverlay,
  setEditOverlay,
  keywords,
  approvedKeywords
}) {
  const [searchInput, setSearchInput] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [inputClass, setInputClass] = useState("editkeyword-input-container");
  const [buttonClass, setButtonClass] = useState("editKeyword-confirm-button");
  const [onSelect, setOnSelect] = useState(false);

  function handleOnChange(event) {
    event.preventDefault();
    setSearchInput(event.target.value); 
    setOnSelect(false);
  }

  useEffect(() => {
    if (!onSelect) {
      if (searchInput.length > 2) {
        setInputClass("card-input-active editkeyword-input-container");
        setShowOptions(true);
      } else {
        setInputClass("editkeyword-input-container");
        setShowOptions(false);
      }
    } else {
      setShowOptions(false);
    }

    if (searchInput.length >= 1) {
      setButtonClass("active-confirm-button editKeyword-confirm-button");
    } else {
      setButtonClass("editKeyword-confirm-button");
    }
  }, [searchInput.length, onSelect]);

  const editKeyword = async () => {
    await updateKeyword(editOverlay.id, searchInput);
    setApproveSuccess(editOverlay.keyword);
    setEdited(editOverlay);
    let result = keywords;
    let index = result.findIndex(
      (element) => element.keyword === editOverlay.keyword
    );
    if (index > -1) {
      result[index].keyword = searchInput;
      result[index].status = "EDITED";
      setKeywords(result);
    }
    setEditOverlay(undefined);
  };

  const select = (keyword) => {
    setSearchInput(keyword);
    setOnSelect(true);
  };

  const makeBold = (item, keyword) => {
    var re = new RegExp(keyword, 'g')
    return (
        item.replace(re, '<b>'+keyword+ '</b>')
    )
}


  return (
    <div className="editKeyword-Overlay">
      <div className="editKeyword-card">
        <div className="editkeyword-card-flex">
          <div className="flex-gap-2">
            <div className="flex-gap-half">
              <div className="horizontal-flex">
                <h3 className="editKeyword-card-heading">
                  Edit keyword {editOverlay.keyword && editOverlay.keyword.length > 35 ? `‘${editOverlay.keyword.substring(0, 35)}...’` : `‘${editOverlay.keyword}’` }
                </h3>
                <svg
                  onClick={() => {
                    setEditOverlay(undefined);
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M6 18L18 6M6 6L18 18"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="body-large">
                Replace with an existing keyword or create new.
              </div>
            </div>
            <div className="flex-gap-half">
              <div className={inputClass}>
                {inputClass === "editkeyword-input-container" && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                      stroke="#B2B7BD"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
                {inputClass !== "editkeyword-input-container" && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10 3.75C6.54822 3.75 3.75 6.54822 3.75 10C3.75 13.4518 6.54822 16.25 10 16.25C13.4518 16.25 16.25 13.4518 16.25 10C16.25 6.54822 13.4518 3.75 10 3.75ZM2.25 10C2.25 5.71979 5.71979 2.25 10 2.25C14.2802 2.25 17.75 5.71979 17.75 10C17.75 14.2802 14.2802 17.75 10 17.75C5.71979 17.75 2.25 14.2802 2.25 10Z"
                      fill="#0336FF"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14.4697 14.4697C14.7626 14.1768 15.2374 14.1768 15.5303 14.4697L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L14.4697 15.5303C14.1768 15.2374 14.1768 14.7626 14.4697 14.4697Z"
                      fill="#0336FF"
                    />
                  </svg>
                )}
                <input
                  className="editkeyword-searchinput"
                  type="text"
                  value={searchInput}
                  onChange={handleOnChange}
                  placeholder="Search or create one..."
                ></input>
                {searchInput.length > 0 && (
                  <svg
                    onClick={() => {
                      setSearchInput("");
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L6.53033 18.5303C6.23744 18.8232 5.76256 18.8232 5.46967 18.5303C5.17678 18.2374 5.17678 17.7626 5.46967 17.4697L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967Z"
                      fill="#404446"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z"
                      fill="#404446"
                    />
                  </svg>
                )}
              </div>
              {showOptions && (
                <div className="editkeyword-searchresult">
                  <div className="editKeyword-each-searchresult body-small-bold-gray">Create: '{searchInput}'</div>
                  {approvedKeywords
                    .filter((ele) => ele.keyword && searchInput && ele.keyword.toLowerCase().includes(searchInput.toLowerCase()))
                    .map((each) => (
                      <div className="editkeyword-outer-divider">
                        <div className="editKeyword-each-searchresult-divider"></div>
                        <div
                          onClick={() => select(each.keyword)}
                          className="editKeyword-each-searchresult body-small-bold-black" 
                          
                        >
                          <label dangerouslySetInnerHTML={{'__html': makeBold(each.keyword, searchInput)}}></label>
                          
                          
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
          <div className="editKeyword-button">
            <button
              className="editKeyword-cancel-button"
              onClick={() => {
                setEditOverlay(undefined);
              }}
            >
              Cancel
            </button>
            <button
              className={buttonClass}
              onClick={editKeyword}
              disabled={searchInput.length > 0 ? false : true}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditKeywordOverlay;
