const host = process.env.REACT_APP_MIDDLEWARE_HOST;
const API_KEY = process.env.REACT_APP_API_KEY;

export async function updateKeyword(keywordId, keyword) {
    let body = {
        keyword: keyword
      }
    let url = host + 'keywords/' + keywordId ;
    const headers = { 'x-api-key' : API_KEY, 'Content-Type': 'application/json'  };
    const result = await fetch(url, {
        method: 'PUT', 
        headers: headers,
        body: JSON.stringify (body)

    }).catch( error => {
        console.log(`Error in updating the keyword : ` + error.message);
        return undefined
    })
    if( result && result.ok){
        let jsonBody = await result.json()
        return jsonBody
    }else{
        console.log(`Error in updating the keyword`);
        return undefined
    }
}

export async function deleteKeyword(keywordId) {
    let url = host + 'keywords/' + keywordId ;
    const headers = { 'x-api-key' : API_KEY };
    const result = await fetch(url, {
        method: 'DELETE', 
        headers: headers
    }).catch( error => {
        console.log(`Error in deleting the keyword : ` + error.message);
        return undefined
    })
    if( result && result.ok){
        let jsonBody = await result.json()
        return jsonBody
    }else{
        console.log(`Error in deleting the keyword`);
        return undefined
    }
}

export async function approveKeyword(keyword) {
    let url = host + 'keywords/approve?keywords=' + keyword;
    const headers = { 'x-api-key' : API_KEY };
    const result = await fetch(url, {
        method: 'POST', 
        headers: headers
    }).catch( error => {
        console.log(`Error in approving the keyword : ` + error.message);
        return undefined
    })
    if( result && result.ok){
        let jsonBody = await result.json()
        return jsonBody
    }else{
        console.log(`Error in approving the keyword`);
        return undefined
    }
}

export async function rejectKeyword(keyword) {
    let url = host + 'keywords/reject?keywords=' + keyword;
    const headers = { 'x-api-key' : API_KEY };
    const result = await fetch(url, {
        method: 'POST', 
        headers: headers
    }).catch( error => {
        console.log(`Error in rejecting the keyword : ` + error.message);
        return undefined
    })
    if( result && result.ok){
        let jsonBody = await result.json()
        return jsonBody
    }else{
        console.log(`Error in rejecting the keyword`);
        return undefined
    }
}

export async function getKeyword() {
    let url = host + 'keywords' ;
    const headers = { 'x-api-key' : API_KEY };
    const result = await fetch(url, {
        method: 'GET', 
        headers: headers
    }).catch( error => {
        console.log(`Error in getting the keyword : ` + error.message);
        return undefined
    })
    if( result && result.ok){
        let jsonBody = await result.json()
        return jsonBody
    }else{
        console.log(`Error in getting the keyword`);
        return undefined
    }
}

export async function getKeywordCountById(keyword) {
    let url = host + 'keywords/count/' +keyword ;
    const headers = { 'x-api-key' : API_KEY };
    const result = await fetch(url, {
        method: 'GET', 
        headers: headers
    }).catch( error => {
        console.log(`Error in getting the keyword : ` + error.message);
        return undefined
    })
    if( result && result.ok){
        let jsonBody = await result.json()
        return jsonBody ? jsonBody.count : undefined
    }else{
        console.log(`Error in getting the keyword`);
        return undefined
    }
}

export async function getKeywordById(keyword) {
    let url = host + 'keywords/' +keyword ;
    const headers = { 'x-api-key' : API_KEY };
    const result = await fetch(url, {
        method: 'GET', 
        headers: headers
    }).catch( error => {
        console.log(`Error in getting the keyword : ` + error.message);
        return undefined
    })
    if( result && result.ok){
        let jsonBody = await result.json()
        return jsonBody
    }else{
        console.log(`Error in getting the keyword`);
        return undefined
    }
}