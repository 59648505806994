
import '../Css/Table.css';
import "../Css/KeywordVideoOverlay.css";
import React, { useState }  from 'react';
import {sectionConcate} from '../Utility/functions'


function VideoDetails({ each, videoOverlay }) {
  const [shortDesc , setShortDesc] = useState(true);
  const [description , setDescription] = useState(each.long_description.length > 100 ? `${each.long_description.substring(0, 100)}...` : each.long_description);
  const [showMoreClass , setShowMoreClass] = useState('video-long-desc video-details-value-txt');

  const showMore = (text) => {
    setShowMoreClass('video-long-desc-show-more video-details-value-txt');
    setDescription(text);
    setShortDesc(false);
  }

  const ShowLess = (text) => {
    setShowMoreClass('video-long-desc video-details-value-txt');
    setDescription(text.length > 100 ? `${text.substring(0, 100)}...` : text);
    setShortDesc(true);
  }

  const otherkeyword = (keywords) => {
    if (keywords) {
      let array = keywords.split(", ")
      array = array.filter( ele => ele !== videoOverlay.keyword )
      return array.join(', ')
    } else {
      return ''
    }   
  }
    
  return (
    <tr className="count-overlay-table-row count-overlay-table-body">
      <td className="count-overlay-table-col count-overlay-title-flex">
        <div className="count-overlay-title">{each.name}</div>
        <div className="video-long-desc-flex">
          <div className={showMoreClass}>
            <div>{each.id}</div>
            <div>{description}</div>
          </div>
          { shortDesc && <button onClick={() => {showMore(each.long_description)}} className="show-more-flex">
            <div className="bulk-action-txt">See more</div>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path d="M14.25 6.75L9 12L3.75 6.75" stroke="#0336FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button> }
          { !shortDesc && <button onClick={() => {ShowLess(each.long_description)}} className="show-more-flex">
            <div className="bulk-action-txt">See Less</div>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path d="M3.75 11.25L9 6L14.25 11.25" stroke="#0336FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button> }
        </div>
      </td>
      <td className="count-overlay-table-col">{each.custom_fields.orientation}</td>
      <td className="count-overlay-table-col">{otherkeyword(each.custom_fields.keywords)}</td>
      <td className="count-overlay-table-col">{each.custom_fields.source}</td>
      <td className="count-overlay-table-col">{sectionConcate(each.custom_fields)}</td>
    </tr> 
  );
}

export default VideoDetails;
