import '../Css/IdleTimeOutHandler.css';
import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import moment from 'moment'
import constants from '../Utility/constant';
import { deleteSession } from '../API/Auth';
const IdleTimeOutHandler = forwardRef((props, ref)=>{
    const[isTimeOut,setIsTimeOut]=useState(false)
    const [countDown, setCountDown] = useState(constants.COUNT_DOWN_INTERVAL+1);
    const [runTimer, setRunTimer] = useState(false);
    let timer = undefined;

    useEffect( () => {
        props.setMinutes(String(Math.floor(countDown / 60)));
    }, [countDown, props]); 

    useEffect( () => {
        let interval;
        if (runTimer) {
            setCountDown(60 * (constants.COUNT_DOWN_INTERVAL+1));
            interval = setInterval(() => {
              setCountDown((countDown) => countDown - 1);
            }, 1000);
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [runTimer]); 
    
    /* eslint-disable */
    useEffect(() => {
        if (countDown <= 0 && runTimer) {
          handleTimeout()
          setRunTimer(false);
          setCountDown(0);
        }
    }, [countDown, runTimer]);

    const handleTimeout = async() => {
        removeEvents();
        clearTimeout(timer);
        setIsTimeOut(false);
        props.setShowModal(false)
        props.setEditMode(undefined);
        props.setTimerToast();
        await deleteSession(props.id);
    }

    useImperativeHandle(ref, () => ( { 
        handleContinueSession () {
            props.setShowModal(false)
            setIsTimeOut(false)
            clearTimeout(timer);
            setRunTimer(false);
        }
    } ) );
    
    
    const events = ['click','load','keydown']
    const eventHandler = (eventType)=>{
        console.log(eventType)
        if(!isTimeOut){
            localStorage.setItem('lastInteractionTime',moment() )
            if(timer){
                props.onActive();
                startTimer();
            }
        }    
    };
    
    const startTimer = () => {
        if(timer){
            clearTimeout(timer)
        }
        timer = setTimeout(()=>{
            let lastInteractionTime=localStorage.getItem('lastInteractionTime')
            const diff = moment.duration(moment().diff(moment(lastInteractionTime)));
            let timeOutInterval = constants.IDLE_TIMEOUT_INTERVAL * 60 * 1000;
            if(isTimeOut){
                clearTimeout(timer)
            } else {
                console.log(diff._milliseconds<timeOutInterval)
                if(diff._milliseconds<timeOutInterval){
                    startTimer();
                    props.onActive();
                }else{
                    props.onIdle();
                    props.setShowModal(true);
                    setRunTimer(true);
                }
            }    
        },constants.IDLE_TIMEOUT_INTERVAL * 60 * 1000)   
    }

    const addEvents = () => {    
        events.forEach(eventName=>{
            window.addEventListener(eventName,eventHandler)
        })    
        startTimer();
    }
    
    const removeEvents = () => {
        events.forEach(eventName=>{
            window.removeEventListener(eventName,eventHandler)
        })
    };

    useEffect(()=>{
        addEvents();
        return (()=>{    
            removeEvents();
            clearTimeout(timer);
        })
    })
    
    return(<div></div>)
        
}   )

export default IdleTimeOutHandler;