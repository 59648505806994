import './App.css';
import "./Css/Header.css";
import Header from "./Component/Header";
import EditMetadata from "./Screen/EditMetadata";
import ApproveKeyword from "./Screen/ApproveKeyword.js";
import ViewMetadata from './Screen/ViewMetadata';
import VideoSaved from './Screen/Video-saved';
import React, { useState, useRef, useEffect } from 'react';
import constants  from './Utility/constant';
import Overlay from './Component/Overlay';
import KeywordVideoOverlay from "./Component/KeywordVideoOverlay";
import EditKeywordOverlay from "./Component/EditKeywordOverlay";
import Loading from './Component/Loading';
import { authenticated } from './API/Auth';
import Alert from './Component/Alert';

function App() {

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isApprover, setApprover] = useState(undefined);
  const [user, setUser] = useState({});
  const [screen, setScreen] = useState(constants.EDIT_METADATA);
  const [scroll, setScroll] = useState(undefined);
  const [video, setVideo] = useState(undefined);
  const [unchangedVideoDetails, setUnchangedVideo] = useState(undefined);
  const [keywords, setKeywords] = useState([]);
  const [showOverlay, setShowOverlay] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showVideoDetails, setShowVideoDetails] = useState(undefined);
  const [showresult, setShowresult] = useState(false);
  const [sessionLocked, setSessionLocked] = useState(undefined); 
  const [searchResult, setSearchResult] = useState({
    count : 0,
    data : [],
    initialOffset: 0,
    endOffset: 0
  });
  const [createNewKeywords, setCreateNewKeywords] = useState([]);
  const [videoOverlay, setVideoOverlay] = useState(undefined);
  const [editOverlay, setEditOverlay] = useState(undefined);
  const [edited, setEdited] = useState(false);
  const [approveSuccess, setApproveSuccess] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  /* eslint-disable */
  const [isActive, setIsActive] = useState(true)
  const [initialOffset, setInitialOffset] = useState(0);
  const [endOffset, setEndOffset] = useState(constants.PAGINATION_ITEMS_PER_PAGE);
  const [currentItems, setCurrentItems] = useState(keywords.slice(initialOffset,endOffset));
  const [approvedKeywords, setApprovedKeywords] = useState([]);
  const [approveLoading, setApproveLoading] = useState(true);

  const idleHandlerRef = useRef(null);
  const[showModal,setShowModal]=useState(false);
  const[minutes,setMinutes]=useState(5)

  const[showAlert,setAlert]=useState(false)

  const initialiseVideo = (object) => {
    setUnchangedVideo(object)
    setVideo(object)
  }

  const handleClick = () => {
    idleHandlerRef.current.handleContinueSession();
  };

  const updateVideo = (updatedVideo) => {
    setVideo(updatedVideo)
  }
  
  const checkAuth = async () => {
    if (["development","qa"].includes(process.env.REACT_APP_ENV) ) {
      let sampleUser = { 
        attributes: { 
          firstName:"User",
          lastName:"Name",
          Groups: ["RGRP_Okta_Brightcove_VideoMetadataTool_Prod_SuperUsers"]
        },
        firstName:"User",
        lastName:"name",

      }
      setApprover(true)
      setUser(sampleUser)
      setIsAuthenticated(true)
      return "user logged"
    } else {
      const result = await authenticated();
      if (result) {
        if (result.user.attributes.Groups.includes("RGRP_Okta_Brightcove_VideoMetadataTool_Prod_SuperUsers")) {
          setApprover(true)
          setUser(result.user)
          setIsAuthenticated(true)
          return "user logged"
        } else if (result.user.attributes.Groups.includes("RGRP_Okta_Brightcove_Prod_Users")) {
          setApprover(false)
          setUser(result.user)
          setIsAuthenticated(true)
          return "user logged"
        } else {
          setUser(result.user)
          setIsAuthenticated(true)
        }     
      } else {
        redirectToLogin();
      }
    }
  }

  useEffect(() => {
    checkAuth();
  },[]);

  const redirectToLogin = () => {
    let redirectUrl = process.env.REACT_APP_LOG_IN_URL;
    window.location.replace(redirectUrl)
  }

  return (
    <div className="App">
      {isAuthenticated ? (
      <div className='app-container'>
        <Header setApprovedKeywords={setApprovedKeywords} showAlert={showAlert} editMode={editMode} setAlert={setAlert} setApproveLoading={setApproveLoading} 
          initialOffset={initialOffset} endOffset={endOffset}
          setCurrentItems={setCurrentItems} isApprover = { isApprover } screen = { screen } 
          setScreen = { setScreen } setKeywords={setKeywords} user = { user } ></Header>
          { screen === constants.EDIT_METADATA && <EditMetadata isLoading={isLoading} setIsLoading={setIsLoading} 
            showAlert={showAlert} setAlert={setAlert} setScroll={setScroll} setMinutes={setMinutes} idleHandler={idleHandlerRef} setShowModal={setShowModal} 
            unchangedVideoDetails={unchangedVideoDetails} initialiseVideo={initialiseVideo} sessionLocked={sessionLocked} 
            setSessionLocked={setSessionLocked} scroll={scroll} showVideoDetails={showVideoDetails} 
            searchResult={searchResult} setSearchResult={setSearchResult}
            setShowVideoDetails={setShowVideoDetails} setShowresult={setShowresult}
            showresult={showresult} video={video} setVideo= {updateVideo} createNewKeywords={createNewKeywords}
            setScreen = { setScreen } setShowOverlay={setShowOverlay} setCreateNewKeywords={setCreateNewKeywords}
            setIsActive={setIsActive} editMode={editMode} setEditMode={setEditMode} user = { user }></EditMetadata> }
          { screen === constants.APPROVE_KEYWORD && <ApproveKeyword approvedKeywords={approvedKeywords} approveLoading={approveLoading} initialOffset={initialOffset} 
            setInitialOffset={setInitialOffset} endOffset={endOffset} setEndOffset={setEndOffset}
            currentItems={currentItems} setCurrentItems={setCurrentItems} keywords={keywords} 
            setKeywords={setKeywords} setVideoOverlay={setVideoOverlay} setEditOverlay={setEditOverlay} 
            edited={edited} setEdited={setEdited} approveSuccess={approveSuccess} setApproveSuccess={setApproveSuccess} ></ApproveKeyword> }
          { screen === constants.VIEW_METADATA && <ViewMetadata setShowresult={setShowresult} setScroll={setScroll} setScreen = { setScreen } video={video} ></ViewMetadata> }
          { screen === constants.REVIEW_METADATA && <ViewMetadata unchangedVideoDetails={unchangedVideoDetails} createNewKeywords={createNewKeywords} setShowresult={setShowresult} setScroll={setScroll} setScreen = { setScreen } video={video} isReviewPage={true}></ViewMetadata> }
          { screen === constants.VIDEO_SAVED && <VideoSaved setCreateNewKeywords={setCreateNewKeywords} setShowVideoDetails={setShowVideoDetails} setEditMode={setEditMode} setShowresult={setShowresult} screen={screen} setScreen = { setScreen } video={video}></VideoSaved> }
          { screen === constants.VIDEO_SAVED_ACTIVATED && <VideoSaved setCreateNewKeywords={setCreateNewKeywords} setShowVideoDetails={setShowVideoDetails} setEditMode={setEditMode} setShowresult={setShowresult} screen={screen} setScreen = { setScreen } video={video}></VideoSaved> }
        <footer className='footer'></footer>
        { showAlert && <Alert unchangedVideoDetails={unchangedVideoDetails} setVideo={setVideo} video={video} setEditMode={setEditMode} setAlert={setAlert}></Alert>}
        { showOverlay && <Overlay setIsLoading={setIsLoading} setSessionLocked={setSessionLocked} setSearchResult={setSearchResult} searchResult={searchResult} video={video} setVideo={setVideo} setScreen = { setScreen } setShowOverlay={setShowOverlay} setEditMode={setEditMode} user = {user}></Overlay> }
        { videoOverlay && <KeywordVideoOverlay videoOverlay={videoOverlay} setVideoOverlay={setVideoOverlay} ></KeywordVideoOverlay>  }
        { editOverlay && <EditKeywordOverlay approvedKeywords={approvedKeywords} edited={edited} setKeywords={setKeywords} setEdited={setEdited} setApproveSuccess={setApproveSuccess} editOverlay={editOverlay} setEditOverlay={setEditOverlay} keywords={keywords}></EditKeywordOverlay>}
        { showModal && <div className='idle-timeout-container'>
          <div className='idle-timeout-popup'>
            <div className='idle-timeout-popup-text'>
              <div className='idle-timeout-popup-heading'>Still there?</div>
                <p className='idle-timeout-popup-desc'>Looks like you’ve stepped away. If you remain inactive for {minutes} mins, you will lose any update you’ve made to this video metadata.</p>
              </div>
              <button className='idle-timeout-popup-button' onClick={()=>{handleClick()}}>Yes, i’m still here.</button>
            </div>
          </div>  }
      </div>  
      ) : (<Loading></Loading> ) }
    </div>
  );
}

export default App;
