import "../Css/ApproveKeyword.css";
import '../Css/Table.css';
import React, { useState }  from 'react';
import "../Css/KeywordVideoOverlay.css";
import { ascSort, desSort, assignSortKeywordType } from '../Utility/functions';
import constants  from '../Utility/constant';
import VideoDetails from "./VideoDetails";
import Loading from '../Component/Loading';

function KeywordVideoOverlay({videoOverlay,setVideoOverlay}) {
  /* eslint-disable */
  const [ sorting , setSorting ] = useState(assignSortKeywordType());

  const sort = (key) => {
    let array = videoOverlay.videoList ? videoOverlay.videoList : []
    if ( sorting[key] === constants.ASC_SORT ) {
        let sortedData = ascSort(array,key)
        setVideoOverlay({...videoOverlay,videoList:sortedData}) 
        let sortType = sorting
        sortType[key] = constants.DES_SORT
        setSorting(sortType)
    } else {
        let sortedData = desSort(array,key)
        setVideoOverlay({...videoOverlay,videoList:sortedData})
        let sortType = sorting
        sortType[key] = constants.ASC_SORT
        setSorting(sortType)
    }
  }

  return (
    <div className="video-overlay">
      <div className="video-table-flex">
        { !videoOverlay.videoList && <div className="video-overlay-loading"><Loading></Loading></div>}
        { videoOverlay.videoList && <div className="video-table-flex-heading">
          <div className="video-table-heading-txt truncate">{videoOverlay.videoList.length} videos with ‘{videoOverlay.keyword}’</div>
          <svg onClick={() => {setVideoOverlay(undefined)}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M6 18L18 6M6 6L18 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>          
        </div>
        }
        { videoOverlay.videoList && <table className="count-overlay-table">
          <thead className="count-overlay-table-row count-overlay-table-header">
            <td className="count-overlay-table-col header-icon">
              <div>Video title/ Brightcove ID/Long description</div>
              <svg onClick={() => {sort(constants.KEYWORD_VIDEO_TITLE)}} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M14.25 6.75L9 12L3.75 6.75" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </td>
            <td className="count-overlay-table-col">Orientation</td>
            <td className="count-overlay-table-col">Other keywords</td>
            <td className="count-overlay-table-col header-icon">
              <div>Brand</div>
              <svg onClick={() => {sort(constants.KEYWORD_VIDEO_BRAND)}} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M14.25 6.75L9 12L3.75 6.75" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </td>
            <td className="count-overlay-table-col header-icon">
              <div>Section</div>
              <svg onClick={() => {sort(constants.KEYWORD_VIDEO_SECTION)}} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M14.25 6.75L9 12L3.75 6.75" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </td>
          </thead>
          <tbody>
            { videoOverlay.videoList.map( (each,index) => {
            return <VideoDetails each={each} videoOverlay={videoOverlay}></VideoDetails>
          } ) } 
          </tbody>
        </table> }  
      </div>
    </div>
    
  );
}

export default KeywordVideoOverlay;
