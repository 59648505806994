import '../Css/InputField.css';
import { searchVideos } from '../API/CMS';
import constants  from '../Utility/constant';
import React, { useState, useEffect } from 'react';
import { deleteSession } from '../API/Auth';

const InputField = ({showAlert, setAlert, editMode, video, setEditMode ,setShowresult, placeholder, setShowVideoDetails, isLoading, setIsLoading, setSearchResult, setVideo }) => {
	const [searchInput, setSearchInput] = useState('');
	const [showExitOnSearch, setShowExitOnSearch] = useState(false);
	const [searchClassName, setSearchClassName] = useState('card-submit');
  	const [disableSearch, setDisableSearch] = useState(true);
	const [inputClass, setInputClass] = useState('card-input');
	const [searchTriggered, setSearchTriggered] = useState(false);

	useEffect(( ) => {
		if (searchInput.length >= 1) {
		  if(searchInput.length > 2) {
			setDisableSearch(false)
			setSearchClassName('card-submit-selected card-submit')
			setInputClass('card-input-active card-input')
		  }
		  else{
			setDisableSearch(true)
			setSearchClassName('card-submit')
			setInputClass('card-input')
		  }
		  setShowExitOnSearch(true)
		  
		} else {
		  setShowExitOnSearch(false)  
		}
	}, [searchInput.length]);

	/* eslint-disable */
	useEffect(( ) => {
		if(searchTriggered && !editMode) {
			triggerSearch( )
			setSearchTriggered(false)
		}
	}, [searchTriggered,editMode,triggerSearch]);

	useEffect(( ) => {
		if(!showAlert) {
			setSearchTriggered(false)
		}
	}, [showAlert]);

	function onKeyEvent (event) {
		if (event.key === "Enter" && searchInput.length > 2) {
			triggerSearch();
		}
	}

	function processSearchInput (event) {  
		setSearchInput(event.target.value)
		if(event.target.value.length === 0) {
			setDisableSearch(true)
			setSearchClassName('card-submit')
			setInputClass('card-input')
			setShowExitOnSearch(false)
		}
	}
	
	function clearSearchInput () {
		setSearchInput('')
		setDisableSearch(true)
		setIsLoading(false)
		setShowExitOnSearch(false)
		setSearchClassName('card-submit')
		setInputClass('card-input')
	}

	async function triggerSearch () {
		if (editMode) {
			setSearchTriggered(true)
			setAlert(true)
		} else {
			setSearchClassName('card-submit')
			setShowresult(false)
			setShowVideoDetails(undefined)
			setEditMode(undefined)
			setIsLoading(true)
			if (video) {
				await deleteSession(video.id);
			}
			setDisableSearch(true)
			let result = await searchVideos(searchInput,0,constants.PAGINATION_ITEMS_PER_PAGE)
			let searchResultValue = {
				input: searchInput,
				count : result.count,
				data : result.items,
				initialOffset : result.offset
			}
			searchResultValue.endOffset = searchResultValue.count >= constants.PAGINATION_ITEMS_PER_PAGE ? searchResultValue.initialOffset + constants.PAGINATION_ITEMS_PER_PAGE : searchResultValue.count;
			setSearchResult(searchResultValue)
			setIsLoading(false)
			setDisableSearch(false)
			setShowresult(true)
			setSearchClassName('card-submit-selected card-submit')
		}
		
	}

	return (
		<div className='card-input-flex'> 
            <div className={inputClass}>
				{	inputClass === 'card-input' &&	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
					<path d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke="#B2B7BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
				</svg> }
				{ inputClass !== 'card-input' && <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M10 3.75C6.54822 3.75 3.75 6.54822 3.75 10C3.75 13.4518 6.54822 16.25 10 16.25C13.4518 16.25 16.25 13.4518 16.25 10C16.25 6.54822 13.4518 3.75 10 3.75ZM2.25 10C2.25 5.71979 5.71979 2.25 10 2.25C14.2802 2.25 17.75 5.71979 17.75 10C17.75 14.2802 14.2802 17.75 10 17.75C5.71979 17.75 2.25 14.2802 2.25 10Z" fill="#0336FF"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M14.4697 14.4697C14.7626 14.1768 15.2374 14.1768 15.5303 14.4697L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L14.4697 15.5303C14.1768 15.2374 14.1768 14.7626 14.4697 14.4697Z" fill="#0336FF"/>
				</svg>}
				<input className='input-class' type="text" id="searchInput" name="searchInput" placeholder={placeholder} onKeyDown={onKeyEvent} onChange={processSearchInput} value={searchInput}  pattern="[a-zA-Z0-9\s]+" ></input>
				{ showExitOnSearch && 
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={clearSearchInput}>
					<path fillRule="evenodd" clipRule="evenodd" d="M18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L6.53033 18.5303C6.23744 18.8232 5.76256 18.8232 5.46967 18.5303C5.17678 18.2374 5.17678 17.7626 5.46967 17.4697L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967Z" fill="#404446"/>
					<path fillRule="evenodd" clipRule="evenodd" d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z" fill="#404446"/>
					</svg>
				}  
            </div>
            <input className={searchClassName} type="submit" value={ isLoading ? "Searching..." : "Search" } disabled={disableSearch} onClick={triggerSearch}></input>
          </div>
	); 
};
  
  
export default InputField;