
import '../Css/Table.css';
import React, { useState, useEffect }  from 'react';
import { dateCreated, ascSort, desSort, assignSortType } from '../Utility/functions';
import constants  from '../Utility/constant';
import { getVideo } from '../API/CMS';
import { getSession } from '../API/Auth';
import { searchVideos } from '../API/CMS';

function Table({editMode, setShowresult, setLoading, importData, setVideo, searchResult, 
    setShowVideoDetails, setSearchResult, setEditMode, setSessionLocked, setAlert, showAlert, isSorted,
    setIsSorted }) {

    const [ sorting , setSorting ] = useState(assignSortType());
    const [ leftArrowClass , setLeftArrow ] = useState('pagination-left-arrow');
    const [ rightArrowClass , setRightArrow ] = useState('pagination-right-arrow');

	const [detailTriggered, setDetailTriggered] = useState(undefined);

    useEffect(( ) => {
		if (searchResult.initialOffset<=0) {
            setLeftArrow('pagination-left-arrow arrow-disabled')
		} else {
            setLeftArrow('pagination-left-arrow arrow-enabled')  
		}
        if (searchResult.endOffset>=searchResult.count) {
            setRightArrow('pagination-right-arrow arrow-disabled')
		} else {
            setRightArrow('pagination-right-arrow arrow-enabled')  
		}
	},[searchResult.endOffset, searchResult.initialOffset, searchResult.count]);
    
    /* eslint-disable */
    const videoDetails = async(videoId) => {
        if (editMode) {
			setDetailTriggered(videoId)
			setAlert(true)
		} else {
            setEditMode(undefined);
            setVideo(undefined);
            setShowVideoDetails(true);
            if (!importData) {
                let getSessionDetails = await getSession(videoId);
                if(getSessionDetails.length !== 0) {
                    setSessionLocked(getSessionDetails[0]);
                } else {
                    setSessionLocked(undefined);
                }
            }
            let result = await getVideo(videoId);
            document.getElementById('video-details-card')?.scrollIntoView({ behavior: 'smooth' })
            setVideo(result);  
        }   
    }

    useEffect(( ) => {
		if(!showAlert) {
			setDetailTriggered(false)
		}
	}, [showAlert]);
    
	useEffect(( ) => {
		if(detailTriggered && !editMode) {
			videoDetails(detailTriggered)
			setDetailTriggered(undefined)
		}
	}, [detailTriggered,editMode,videoDetails]);

    function sort(key) {
        setIsSorted({ key: key, type:sorting[key] });
        if ( sorting[key] === constants.ASC_SORT ) {
            let sortedData = ascSort(searchResult.data,key)
            setSearchResult({ ...searchResult,data: sortedData});
            setSorting({...sorting, [key]:constants.DES_SORT})
        } else {
            let sortedData = desSort(searchResult.data,key)
            setSearchResult({ ...searchResult, data: sortedData});
            setSorting({...sorting, [key]:constants.ASC_SORT})
        }   
    }

    function sortTheResult(key, array, type) {
        if ( type === constants.ASC_SORT ) {
            return ascSort(array,key);
        } else {
            return desSort(array,key);
        }
    }
    
    const leftClick = async() => {
        if(searchResult.initialOffset>0) {
            if (importData){
                window.scrollTo(0,0);
            }
            setLoading(true)
            setShowresult(false)
            let endOffset = searchResult.initialOffset
            let initialOffset = searchResult.initialOffset - constants.PAGINATION_ITEMS_PER_PAGE
            let result = await searchVideos(searchResult.input,initialOffset,constants.PAGINATION_ITEMS_PER_PAGE)
            let searchResultValue = {
                input: searchResult.input,
                count : result.count,
                data : isSorted ? sortTheResult(isSorted.key, result.items, isSorted.type) : result.items,
                initialOffset : result.offset,
                endOffset : endOffset
            }
            setSearchResult(searchResultValue)
            setLoading(false)
            setShowresult(true)
        } 
    }
      
    const rightClick = async() => {
        if( searchResult.endOffset<searchResult.count ) {
            if (importData){
                window.scrollTo(0,0);
            }
            setLoading(true)
            setShowresult(false)
            let initialOffset = searchResult.endOffset
            let endOffset = searchResult.endOffset + constants.PAGINATION_ITEMS_PER_PAGE
            let result = await searchVideos(searchResult.input,initialOffset,constants.PAGINATION_ITEMS_PER_PAGE)
            let searchResultValue = {
                input: searchResult.input,
                count : result.count,
                data : isSorted ? sortTheResult(isSorted.key, result.items, isSorted.type) : result.items,
                initialOffset : result.offset,
                endOffset : endOffset
            }
            setSearchResult(searchResultValue)
            setLoading(false)
            setShowresult(true)
        } 
    }

    

    
  return (
    <div>
        <table className='search-result-table'>
            <thead className='search-result-table-row search-result-header'>
                <td className='search-result-table-col'>Status</td>
                <td className='search-result-table-col table-header-img'>
                    <div>Video Title / Brightcove ID</div>
                    <svg className='video-title-icon'  onClick={()=>{sort(constants.SORT_VIDEO_TITLE)}} xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path d="M13.639 9.33333L8.97233 14M8.97233 14L4.30566 9.33333M8.97233 14L8.97233 2" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </td>
                <td className='search-result-table-col table-header-img'>
                    <div className='seo-owner-div'>SEO Owner</div>
                    <svg className='table-icon' onClick={()=>{sort(constants.SORT_SEO_OWNER)}} xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                        <path d="M19.9727 9L12.9727 16L5.97266 9" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </td>
                <td className='search-result-table-col table-header-img'>
                    <div>Source</div>
                    <svg className='table-icon' onClick={()=>{sort(constants.SORT_SOURCE)}} xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                        <path d="M19.9727 9L12.9727 16L5.97266 9" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </td>
                <td className='search-result-table-col table-header-img'>
                    <div>Author</div>
                    <svg className='table-icon' onClick={()=>{sort(constants.SORT_AUTHOR)}} xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                        <path d="M19.9727 9L12.9727 16L5.97266 9" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </td>
                <td className='search-result-table-col'>Orientation</td>
                <td className='search-result-table-col table-header-img'>
                    <div>Date Created</div>
                    <svg className='table-icon' onClick={()=>{sort(constants.SORT_CREATED_AT)}} xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                        <path d="M19.9727 9L12.9727 16L5.97266 9" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </td>
                <td className='search-result-table-col'></td>
            </thead>
            <tbody>
            { searchResult.data.map( ( video, index) => {    return [
                <tr className='search-result-table-row search-result-body '>
                    <td className='search-result-table-col'>
                    { video.state === 'ACTIVE' && 
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M12.4867 21.5999C17.7887 21.5999 22.0867 17.3018 22.0867 11.9999C22.0867 6.69797 17.7887 2.3999 12.4867 2.3999C7.18479 2.3999 2.88672 6.69797 2.88672 11.9999C2.88672 17.3018 7.18479 21.5999 12.4867 21.5999ZM16.9352 10.4484C17.4039 9.9798 17.4039 9.22 16.9352 8.75137C16.4666 8.28275 15.7068 8.28275 15.2382 8.75137L11.2867 12.7028L9.73525 11.1514C9.26662 10.6827 8.50682 10.6827 8.03819 11.1514C7.56956 11.62 7.56956 12.3798 8.03819 12.8484L10.4382 15.2484C10.9068 15.7171 11.6666 15.7171 12.1352 15.2484L16.9352 10.4484Z" fill="#4AA90F"/>
                    </svg> }
                    { video.state === 'INACTIVE' && 
                    <span className='inactive-icon'></span> }
                    </td>
                    <td className='search-result-table-col'>
                        <div className='data-text'>{video.name.length > 25 ?
                            `${video.name.substring(0, 25)}...` : video.name
                        }</div>
                        <div className='bc-id'>{video.id}</div>
                    </td>
                    <td className='search-result-table-col'>{video.custom_fields.seo_owner}</td>
                    <td className='search-result-table-col'>{video.custom_fields.source}</td>
                    <td className='search-result-table-col'>{video.custom_fields.author}</td>
                    <td className='search-result-table-col'>{video.custom_fields.orientation}</td>
                    <td className='search-result-table-col'>{dateCreated(video.created_at)}</td>
                    <td className='search-result-table-col'>
                        <button className='detail-button' onClick={() =>{videoDetails(video.id)}}>
                            Details<span className='detail-button-icon'></span>
                        </button>
                    </td>
                </tr>
            ]  } ) }
            </tbody> 
        </table>
        <div className='pagination'>
            <span className={leftArrowClass} onClick={leftClick}></span>
            <div className='pagination-row'>
                <div className='pagination-column pagination-column-1'>{searchResult.count > 0 ? searchResult.initialOffset+1 : 0} - {searchResult.endOffset > searchResult.count ? (searchResult.count > 0 ? searchResult.count : 0) : searchResult.endOffset}</div>
                <div className='pagination-column pagination-column-2'>of</div>
                <div className='pagination-column pagination-column-3'>{searchResult.count}</div>
            </div>
            <span className={rightArrowClass} onClick={rightClick}></span>
        </div>
        
    </div>
    
    
  );
}

export default Table;
