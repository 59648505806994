
import React, { useState } from 'react';
import InputField from "../Component/InputField";
import Loading from '../Component/Loading';
import Table from '../Component/Table';
import VideoDetailsCard from '../Component/VideoDetailsCard'


import '../Css/ImportMetadata.css'

const ImportMetadata = ({saveSearchResult, existingResult, video, setVideo, 
  setScreen, setShowOverlay, setEditMode, user, setSessionLocked, setEditLoading } ) => {

    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [renderVideoDetails, setRenderVideoDetails] = useState(undefined);
    const [showVideoDetails, setShowVideoDetails] = useState(undefined);
    const [showresult, setShowresult] = useState(false);
    const [isSorted , setIsSorted] = useState(undefined);
    const [searchResult, setSearchResult] = useState({
      count : 0,
      data : [],
      initialOffset: 0,
      endOffset: 0
    });

    const exitOverlay = () => {
      setSearchResult({
        showResult: false,
        count : 0,
        data : [],
        initialOffset: 0,
        endOffset: 0
      })
      setRenderVideoDetails(undefined)
      setShowOverlay(false)
    }
 
  
  return ( 
    <div className="import-metadata-container" >
      <div className="importMetadataHeader">
        <h3 className="importMetadataHeaderText">Copy metadata from another Video Title</h3>
        <svg onClick={exitOverlay} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M6 18L18 6M6 6L18 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </div>
      <div className="importMetadataBody">
        <div className="importMetadataBodyContainer">
            <div className="importMetadataBodyText">Search and select a video to copy its Metadata. You will be able to edit and review the fields before saving.</div>
            <div className="importMetadataBodyText">Video title, Brightcove ID or Author</div>
            <InputField setEditMode={setEditMode} setShowresult={setShowresult} placeholder={'Search anything...'} isLoading={isLoading} setIsLoading={setIsLoading} setSearchResult={setSearchResult} setVideo={setRenderVideoDetails} setShowVideoDetails={setShowVideoDetails}></InputField>
        </div>  
      </div>
      {loading && <Loading></Loading>}
      { showresult && <div>{searchResult.count} videos found</div> }
      { showresult && <Table isSorted={isSorted} setIsSorted={setIsSorted} 
      setShowresult={setShowresult} setLoading={setLoading} searchResult={searchResult} 
      setSearchResult={setSearchResult}  setVideo={setRenderVideoDetails} 
      setShowVideoDetails={setShowVideoDetails} setEditMode={setEditMode} importData={true} ></Table> }
      { showVideoDetails && <VideoDetailsCard setSessionLocked={setSessionLocked} setLoading={setEditLoading} saveSearchResult={saveSearchResult} existingResult={existingResult} video={renderVideoDetails} existingVideo={video} copyVideo={setVideo} setVideo={setRenderVideoDetails} sessionLocked={true} importData={true} setScreen={setScreen } setShowOverlay={setShowOverlay} setEditMode={setEditMode} user={user}></VideoDetailsCard>}
      { isLoading && <Loading></Loading> }
    </div> 
  );
};

export default ImportMetadata;

